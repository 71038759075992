import React from 'react';
import AllBranchs from '../components/Admin/AllBranchs';
import AdminSideBar from '../components/Admin/Layout/AdminSideBar';
import AdminHeader from '../components/Layout/AdminHeader';

const AdminAllBranch = () => {
  return (
    <div>
      <AdminHeader />
      <div className="flex items-start justify-between w-full">
        <div className="w-[70px] lg:w-[320px] xl:w-[290px] md:w-[60px]">
          <AdminSideBar active={3} />
        </div>
        <AllBranchs />
      </div>
    </div>
  );
};

export default AdminAllBranch;
