import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllUsers } from '../../redux/actions/user';
import {
  AiOutlineRight,
  AiOutlineLeft,
  AiOutlineEyeInvisible,
  AiOutlinePlus,
} from 'react-icons/ai';
import { RxCross1 } from 'react-icons/rx';
import axios from 'axios';
import { server } from '../../server';
import { toast } from 'react-toastify';

import ReactPaginate from 'react-paginate';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import { getCurrentTime } from '../../utils/timeConvertor';

const itemsPerPage = 20;

const AllUsers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { users } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState('');
  const [openAdd, setOpenAdd] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const [addNew, setAddNew] = useState(false);

  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const [visible, setVisible] = useState(false);
  const [allUsers, setAllUsers] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchShow, setSearchShow] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);

  const conponentPDF = useRef();

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    dispatch(getAllUsers());
    setAllUsers(users);
  }, [dispatch]);

  useEffect(() => {
    setAllUsers(users);
  }, [users]);

  const adminRole = ['Admin', 'MiniAdmin', 'StorageAdmin'];

  const offset = currentPage * itemsPerPage;
  const paginatedData = allUsers?.slice(offset, offset + itemsPerPage);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filteredProducts =
      users &&
      users.filter((person) => {
        return person.name.toLowerCase().includes(term.toLowerCase());
      });

    if (term === '') {
      setSearchShow(false);
      dispatch(getAllUsers());
      setAllUsers(users);
    } else {
      setSearchShow(true);
      setAllUsers(filteredProducts);
    }
  };

  // const pageStyle = '@page { size: A4 portrait;}';

  const generatePDF = useReactToPrint({
    content: () => conponentPDF.current,
    pageStyle: `
    @page {
      size: A4 portrate;
      margin: 1cm;
    }
    @media print {
      body {
        margin: 1;
        padding: 1;
      }
    }
  `,
    documentTitle: 'All Users',
    // onAfterPrint: () => alert('Data saved in PDF'),
  });

  const handleDelete = async (id) => {
    if (users.role !== 'Admin') {
      await axios
        .delete(`${server}/user/delete-user/${id}`, { withCredentials: true })
        .then((res) => {
          toast.success(res.data.message);
        });

      dispatch(getAllUsers());
    }
  };

  const handleCheck = (event) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      //Add checked item into checkList
      setRole(event.target.value);
    } else {
      setRole('Admin');
    }
  };

  const handleSubmitPassword = async () => {
    if (userId) {
      await axios
        .put(
          `${server}/user/update-user-password/${userId}`,
          {
            password,
          },
          { withCredentials: true }
        )
        .then((res) => {
          toast.success('Password updated succesfully!');
          setPassword('');
          setOpenPassword(false);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  const handleSubmit = async () => {
    if (userId) {
      await axios
        .put(
          `${server}/user/update-user-info/${userId}`,
          {
            name,
            // password,
            phoneNumber,
            role,
          },
          { withCredentials: true }
        )
        .then((res) => {
          toast.success('User info updated succesfully!');
          navigate('/admin/users');
          dispatch(getAllUsers());
          setName('');
          setPhoneNumber('');
          setUserId('');
          setOpenAdd(false);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      const currentDate = getCurrentTime();
      await axios
        .post(`${server}/user/create-user`, {
          name,
          password,
          phoneNumber,
          role,
          createdAt: currentDate,
        })
        .then((res) => {
          //handleUpdateLevels();
          toast.success('User info added succesfully!');
          navigate('/admin/users');
          setName('');
          setPhoneNumber('');
          setPassword('');
          setUserId('');
          dispatch(getAllUsers());
          setOpenAdd(false);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
      //handleCreateLevels();
      // dispatch(createBranch(newBranch));
    }
  };

  return (
    <div className="container mt-[65px]  px-3 mx-auto">
      <div className="mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 pt-3 gap-4">
        <div className="rounded  border-gray-300 ">
          <div className="relative flex w-full items-stretch">
            <input
              type="search"
              className="relative m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-[#F4AA8F] focus:text-neutral-700  focus:outline-none "
              placeholder="Search by name"
              aria-label="Search"
              aria-describedby="button-addon1"
              value={searchTerm}
              onChange={handleSearchChange}
            />

            <div
              className="relative z-[2] flex items-center ltr:rounded-r rtl:rounded-l bg-[#222222]  px-6 py-2.5 text-xs font-medium uppercase leading-tight text-[#F4AA8F] shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
              type="button"
              id="button-addon1"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  // fillrule="evenodd"
                  d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                  // cliprule="evenodd"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="rounded ">
          <div className="flex justify-end items-center w-full">
            <div className="relative px-2 flex justify-end w-1/5">
              <button
                className="text-[#F4AA8F] hover:text-[#fff]  appearance-none bg-[#404041] hover:bg-[#222222] text-center block px-4 h-[35px] border border-gray-300 rounded"
                onClick={generatePDF}
              >
                {t('admin.print')}{' '}
              </button>
            </div>
            <div
              onClick={() => {
                setOpenAdd(true);
                setAddNew(true);
                setOpenPassword(false);
              }}
              className="items-stretch mr-2 hover:text-[#fff] "
            >
              <button
                to="/admin-Create-user"
                className="w-full flex items-center px-1 h-[35px]  rounded bg-[#F4AA8F] hover:bg-[#413632]  "
              >
                <div className="p-1">
                  <span className="text-black text-[12px] md:text-[14px] lg:text-[15px] xl:text-[15px]  font-[600] flex items-center hover:text-[#fff] ">
                    <AiOutlinePlus
                      size={20}
                      color={200}
                      className="mr-1 items-center"
                    />
                    {t('admin.add_user')}{' '}
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full border-b py-1"></div>
      <div ref={conponentPDF} className="container  mx-auto">
        <div className="mx-auto grid grid-cols-2 gap-4">
          <div className="flex justify-start items-center px-3 py-3 ">
            <p className=" text-[12px] md:text-[16px] lg:text-[16px] xl:text-[16px] rtl:xl:text-[20px] font-[500]">
              {t('admin.all_users')}{' '}
            </p>
          </div>
          <div className="flex justify-end items-center px-3 py-3 ">
            <span className="text-black text-[12px] md:text-[14px] lg:text-[15px] xl:text-[16px]  font-[500]">
              ({allUsers?.length}) {t('admin.user')}{' '}
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <div className=" -my-2 overflow-x-auto ">
            <div className="inline-block min-w-full py-2 align-middle  md:px-2 lg:px-2">
              <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className="bg-[#F1F2F7] dark:bg-gray-800">
                    <tr>
                      <th
                        scope="col"
                        className="py-3 px-4 text-sm font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>ID</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>{t('admin.name')}</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>{t('customer.phone')}</span>
                        </div>
                      </th>

                      <th
                        scope="col"
                        className="py-3 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>{t('admin.role')}</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>{t('admin.create_at')}</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-4 text-md  font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center justify-end px-3 gap-x-3">
                          <span>{t('admin.actions')}</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                    {paginatedData?.map((item, index) => (
                      <tr key={index}>
                        <td className="px-4 py-3 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item._id}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-3 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item.name}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-3 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item.phoneNumber}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>

                        <td className="px-4 py-3 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item.role}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-3 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item.createdAt?.split(' ')[0]}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>

                        <td className="px-4 py-3 text-sm whitespace-nowrap">
                          <div className="flex items-center justify-end px-3 gap-x-6 ">
                            <button
                              onClick={() =>
                                setUserId(item._id) || setOpen(true)
                              }
                              className="text-[#C51605] transition-colors duration-200 dark:hover:text-red-500 dark:text-gray-300 hover:text-red-500 focus:outline-none"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-5 h-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                />
                              </svg>
                            </button>

                            <button
                              onClick={() => {
                                setOpenAdd(true);
                                setAddNew(false);
                                setUserId(item._id);
                                setName(item.name);
                                setPhoneNumber(item.phoneNumber);
                                setPassword(item.password);
                                setRole(item.role);
                                setOpenPassword(false);
                              }}
                              className="text-[#F4AA8F] transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-[#404041] focus:outline-none"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-5 h-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                />
                              </svg>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center mt-2">
        <ReactPaginate
          breakLabel={'...'}
          nextLabel={<AiOutlineRight size="20" className="rtl:-scale-x-100" />}
          previousLabel={
            <AiOutlineLeft size="20" className="rtl:-scale-x-100" />
          }
          pageRangeDisplayed={2}
          pageCount={Math.ceil(users?.length / itemsPerPage)}
          onPageChange={handlePageChange}
          marginPagesDisplayed={2}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="active"
          shape="rounded"
        />
      </div>

      <div className="flex justify-center items-center px-2 mr-3">
        {/* <div className="w-full min-h-[45vh] mt-3  bg-white rounded">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={15}
            disableSelectionOnClick
            autoHeight
          />
        </div> */}
        {openAdd && (
          <div>
            <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
              <div
                role="alert"
                className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
              >
                <div className="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
                  <div className="w-full flex justify-start text-gray-600 mb-3"></div>
                  <h1 className="text-gray-800 text-sm font-bold tracking-normal leading-tight mb-6">
                    {userId ? t('admin.update_user') : t('admin.add_user')}
                  </h1>
                  <label
                    htmlFor="name"
                    className="block uppercase tracking-wide text-gray-700 text-md  mb-2 "
                  >
                    {t('admin.name')}
                  </label>
                  <input
                    id="name"
                    className="mb-5 mt-2 px-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    placeholder="Engineering Name"
                    type="text"
                    required
                    name="name"
                    value={name || ''}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <label
                    htmlFor="email2"
                    className="block uppercase tracking-wide text-gray-700 text-md  mb-2"
                  >
                    {t('customer.phone')}
                  </label>
                  <input
                    id="phone"
                    className="mb-5 mt-2 px-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    placeholder="phone Number"
                    type="text"
                    required
                    name="phone"
                    value={phoneNumber || ''}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                  {addNew && (
                    <div className="mt-1 relative">
                      <label
                        htmlFor="email2"
                        className="block uppercase tracking-wide text-gray-700 text-md  mb-2"
                      >
                        {t('admin.new_password')}
                      </label>
                      <input
                        type={visible ? 'text' : 'password'}
                        name="password"
                        autoComplete="current-password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="mb-5 mt-2 px-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                      />
                      {visible ? (
                        <AiOutlineEyeInvisible
                          className="absolute ltr:right-2 rtl:left-2 top-10 cursor-pointer"
                          size={25}
                          onClick={() => setVisible(false)}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          className="absolute ltr:right-2 rtl:left-2 top-10 cursor-pointer"
                          size={25}
                          onClick={() => setVisible(true)}
                        />
                      )}
                    </div>
                  )}

                  <div>
                    <label className="block uppercase tracking-wide text-gray-700 text-md  mb-2">
                      {t('admin.role')}
                    </label>
                    {adminRole.map((item, index) => (
                      <div key={index} className="flex items-center mb-4">
                        <input
                          id="default-checkbox"
                          type="checkbox"
                          checked={role && role === item ? true : false}
                          name={item}
                          value={item || ''}
                          onChange={handleCheck}
                          className="w-4 h-4  text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for="default-checkbox"
                          className="px-2 text-md font-medium text-gray-900 dark:text-gray-300"
                        >
                          {item}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="flex items-center justify-center w-full mt-6">
                    <button
                      className="focus:outline-none transition duration-150 ease-in-out hover:bg-[#F4AA8F] bg-[#404041] hover:text-[#404041] rounded text-white px-8 py-2 text-md"
                      onClick={() => handleSubmit()}
                    >
                      {t('admin.save')}{' '}
                    </button>
                    <button
                      className="focus:outline-none ml-3 rtl:mr-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-md"
                      onClick={() => {
                        setOpenAdd(false);
                        setName('');
                        setPhoneNumber('');
                        setPassword('');
                        setRole('');
                        setUserId('');
                      }}
                    >
                      {t('admin.cancel')}{' '}
                    </button>
                    {addNew && addNew ? null : (
                      <button
                        className="w-full flex items-center hover:text-black text-[#fff] bg-gray-600 hover:bg-gray-300 focus:outline-none ml-3  transition duration-150 border rounded px-4 py-2 text-md"
                        onClick={() => {
                          setOpenPassword(true);
                        }}
                      >
                        {t('admin.change_password')}{' '}
                      </button>
                    )}
                  </div>
                  <div className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out">
                    <RxCross1
                      size={20}
                      onClick={() => {
                        setOpenAdd(false);
                        setName('');
                        setPhoneNumber('');
                        setPassword('');
                        setRole('');
                        setUserId('');
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {openPassword && (
          <div>
            <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
              <div
                role="alert"
                className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
              >
                <div className="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
                  <div className="w-full flex justify-start text-gray-600 mb-3"></div>
                  <h1 className="text-gray-800 text-md font-bold tracking-normal leading-tight mb-6">
                    {t('admin.change_password')}{' '}
                  </h1>
                  <div className="mt-1 relative">
                    <label
                      htmlFor="email2"
                      className="block uppercase tracking-wide text-gray-700 text-md  mb-2"
                    >
                      {t('admin.new_password')}{' '}
                    </label>
                    <input
                      type={visible ? 'text' : 'password'}
                      name="password"
                      autoComplete="current-password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="mb-5 mt-2 px-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    />
                    {visible ? (
                      <AiOutlineEyeInvisible
                        className="absolute ltr:right-2 rtl:left-2 top-10 cursor-pointer"
                        size={25}
                        onClick={() => setVisible(false)}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        className="absolute ltr:right-2 rtl:left-2 top-10 cursor-pointer"
                        size={25}
                        onClick={() => setVisible(true)}
                      />
                    )}
                  </div>

                  <div className="flex items-center justify-center w-full mt-6">
                    <button
                      className="focus:outline-none transition duration-150 ease-in-out hover:bg-[#F4AA8F] bg-[#404041] hover:text-[#404041] rounded text-white px-8 py-2 text-md"
                      onClick={() => handleSubmitPassword()}
                    >
                      {t('admin.save')}{' '}
                    </button>
                    <button
                      className="focus:outline-none ml-3 rtl:mr-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-md"
                      onClick={() => {
                        setOpenPassword(false);
                        setPassword('');
                      }}
                    >
                      {t('admin.cancel')}{' '}
                    </button>
                  </div>
                  <div className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out">
                    <RxCross1
                      size={20}
                      onClick={() => {
                        setOpenPassword(false);
                        setPassword('');
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {open && (
          <div className="w-full fixed  top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="container mx-auto w-11/12 md:w-2/3 max-w-lg min-h-[10vh]  bg-white rounded shadow  p-2">
              {/* <div className="w-full flex justify-end cursor-pointer p-2"> */}
              <div className="w-full flex justify-end cursor-pointer top-0 right-0 p-2 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out">
                <RxCross1 size={22} onClick={() => setOpen(false)} />
              </div>
              <h3 className="text-[18px] text-center py-3 px-6 font-Poppins text-[#000000cb]">
                {t('admin.delete_user')}{' '}
              </h3>
              <div className="flex items-center justify-center w-full p-2">
                <button
                  className="focus:outline-none transition duration-150 ease-in-out hover:bg-[#404041] bg-[#C51605] rounded text-white px-8 py-2 text-md"
                  onClick={() => setOpen(false) || handleDelete(userId)}
                >
                  {t('admin.delete')}{' '}
                </button>
                <button
                  className="focus:outline-none ml-3 rtl:mr-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-md"
                  onClick={() => setOpen(false)}
                >
                  {t('admin.cancel')}{' '}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllUsers;
