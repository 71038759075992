import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
// import { DataGrid } from '@material-ui/data-grid';
import { Button } from '@material-ui/core';
import { BsEye } from 'react-icons/bs';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { getAllCustomersAdmin } from '../../redux/actions/admin';
import ReactPaginate from 'react-paginate';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import { getAllBranchs } from '../../redux/actions/branch';
import { getAllUsers } from '../../redux/actions/user';

const itemsPerPage = 20;

const AllCompletes = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let { state } = useLocation();
  const { customers } = useSelector((state) => state.admin);
  const { branches } = useSelector((state) => state.branches);
  const { user } = useSelector((state) => state.user);
  const [allCustomers, setAllCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  // const [searchShow, setSearchShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const conponentPDF = useRef();

  useEffect(() => {
    // const isItemExists =
    //   customers &&
    //   customers.filter(
    //     (item) => item.levels[item.levels?.length - 1]?.status === 'Complete'
    //   );
    // setAllCustomers(isItemExists);

    if (state.sellerId === '' && state.branchId !== '') {
      const isItemExists =
        customers &&
        customers.filter((item) => {
          return (
            item.levels[item.levels?.length - 1]?.status === 'Complete' &&
            item.branchId === state.branchId
          );
        });
      setAllCustomers(isItemExists);
    } else if (state.sellerId !== '' && state.branchId === '') {
      const isItemExists =
        customers &&
        customers.filter((item) => {
          return (
            item.levels[item.levels?.length - 1]?.status === 'Complete' &&
            item.engineeringId === state.sellerId
          );
        });
      setAllCustomers(isItemExists);
    } else if (state.sellerId !== '' && state.branchId !== '') {
      const isItemExists =
        customers &&
        customers.filter(
          (item) =>
            item.levels[item.levels?.length - 1]?.status === 'Complete' &&
            item.engineeringId === state.sellerId &&
            item.branchId === state.branchId
        );
      setAllCustomers(isItemExists);
    } else {
      const isItemExists =
        customers &&
        customers.filter((item) => {
          return item.levels[item.levels?.length - 1]?.status === 'Complete';
        });
      setAllCustomers(isItemExists);
    }
  }, [customers]);

  useEffect(() => {
    dispatch(getAllCustomersAdmin());
    dispatch(getAllBranchs());
    dispatch(getAllUsers());
  }, [dispatch]);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * itemsPerPage;
  const paginatedData = allCustomers?.slice(offset, offset + itemsPerPage);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filteredProducts =
      allCustomers &&
      allCustomers.filter((person) => {
        return person.name.toLowerCase().includes(term.toLowerCase());
      });

    if (term === '') {
      // setSearchShow(false);
      dispatch(getAllCustomersAdmin());
      setAllCustomers(allCustomers);
    } else {
      // setSearchShow(true);
      setAllCustomers(filteredProducts);
    }
  };
  // const pageStyle = '@page { size: 1in 2in }';

  const generatePDF = useReactToPrint({
    content: () => conponentPDF.current,
    pageStyle: `
    @page {
      size: A4 portrate;
      margin: 1cm;
    }
    @media print {
      body {
        margin: 1;
        padding: 1;
      }
    }
  `,
    documentTitle: 'All Completed',
    // onAfterPrint: () => alert('Data saved in PDF'),
  });

  return (
    <div className="container mt-[65px]  px-3 mx-auto">
      <div className="mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 pt-3 gap-4">
        <div className="rounded  border-gray-300 ">
          <div className="relative flex w-full items-stretch">
            <input
              type="search"
              className="relative m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-[#F4AA8F] focus:text-neutral-700  focus:outline-none "
              placeholder="Search by name"
              aria-label="Search"
              aria-describedby="button-addon1"
              value={searchTerm}
              onChange={handleSearchChange}
            />

            <div
              className="relative z-[2] flex items-center ltr:rounded-r rtl:rounded-l bg-[#222222]  px-6 py-2.5 text-xs font-medium uppercase leading-tight text-[#F4AA8F] shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
              type="button"
              id="button-addon1"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  // fillrule="evenodd"
                  d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                  // cliprule="evenodd"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="rounded ">
          <div className="flex justify-end items-center w-full">
            <div className="relative  md:px-2 lg:px-2 xl:px-2 flex justify-end w-1/5">
              <button
                className="text-[#F4AA8F] hover:text-[#fff]  appearance-none bg-[#404041] hover:bg-[#222222] text-center block px-4 h-[35px] border border-gray-300 rounded"
                onClick={generatePDF}
              >
                {t('admin.print')}{' '}
              </button>
            </div>
            {/* <div className="items-stretch mr-2 hover:text-[#fff] ">
              <button
                to="/dashboard-register-customer"
                className="w-full flex items-center px-1 h-[35px]  rounded bg-[#F4AA8F] hover:bg-[#413632]  "
              >
                <div className="p-1">
                  <span className="text-black text-[10px] md:text-[14px] lg:text-[15px] xl:text-[15px]  font-[500] flex items-center">
                    ({allCustomers?.length}) customers
                  </span>
                </div>
              </button>
            </div> */}
          </div>
        </div>
      </div>
      <div className="w-full border-b py-1"></div>
      {/* Table */}
      <div ref={conponentPDF} className="container  mx-auto">
        <div className="mx-auto grid grid-cols-2 gap-4">
          <div className="flex justify-start items-center px-3 py-3 ">
            <p className=" text-[10px] md:text-[16px] lg:text-[16px] xl:text-[16px] rtl:xl:text-[20px] font-[500]">
              {t('main.completed')}{' '}
            </p>
          </div>
          <div className="flex justify-end items-center px-3 py-3 ">
            <span className="text-black text-[10px] md:text-[14px] lg:text-[15px] xl:text-[16px]  font-[500]">
              ({allCustomers?.length}) {t('admin.customer')}
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <div className=" -my-2 overflow-x-auto ">
            <div className="inline-block min-w-full py-2 align-middle  md:px-2 lg:px-2">
              <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className="bg-[#F1F2F7] dark:bg-gray-800">
                    <tr>
                      {/* <th
                        scope="col"
                        className="py-3.5 px-4 text-sm font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>ID</span>
                        </div>
                      </th> */}
                      <th
                        scope="col"
                        className="py-3 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>{t('admin.name')}</span>
                        </div>
                      </th>
                      {user && user.role === 'Admin' ? (
                        <th
                          scope="col"
                          className="py-3 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                        >
                          <div className="flex items-center gap-x-3">
                            <span>{t('customer.phone')}</span>
                          </div>
                        </th>
                      ) : null}
                      <th
                        scope="col"
                        className="py-3 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>{t('admin.engineer_name')}</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>{t('customer.status')}</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>{t('customer.branch')}</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>{t('admin.create_at')}</span>
                        </div>
                      </th>
                      {user && user.role === 'Admin' ? (
                        <th
                          scope="col"
                          className="py-3 px-4 text-md  font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                        >
                          <div className="flex items-center justify-end px-3 gap-x-3">
                            <span>{t('admin.actions')}</span>
                          </div>
                        </th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                    {paginatedData?.map((item, index) => (
                      <tr key={index}>
                        {/* <td className="px-4 py-42 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item._id}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td> */}
                        <td className="px-4 py-3 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item.name}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>
                        {user && user.role === 'Admin' ? (
                          <td className="px-4 py-3 text-sm font-medium text-gray-700 whitespace-nowrap">
                            <div className="inline-flex items-center gap-x-3">
                              <div className="flex items-center gap-x-2">
                                <div>
                                  <h2 className="font-medium text-gray-800 dark:text-white ">
                                    {item.phone}
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </td>
                        ) : null}
                        <td className="px-4 py-3 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item.engineering}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-3 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item.levels[item.levels?.length - 1]?.status}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-3 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {branches
                                    .filter(
                                      (itemBranch) =>
                                        itemBranch._id === item.branchId
                                    )
                                    .map((itemAmount) => itemAmount.name)}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-3 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item.createdAt}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>
                        {user && user.role === 'Admin' ? (
                          <td className="px-4 py-3 text-sm whitespace-nowrap">
                            <div className="flex items-center justify-end px-3 gap-x-6 ">
                              <Link to={`/admin/customers/${item._id}`}>
                                <Button>
                                  <BsEye
                                    size={22}
                                    className=""
                                    fill="#F4AA8F"
                                  />
                                </Button>
                              </Link>
                            </div>
                          </td>
                        ) : null}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center mt-2">
        <ReactPaginate
          breakLabel={'...'}
          nextLabel={<AiOutlineRight size="20" className="rtl:-scale-x-100" />}
          previousLabel={
            <AiOutlineLeft size="20" className="rtl:-scale-x-100" />
          }
          pageRangeDisplayed={2}
          pageCount={Math.ceil(allCustomers?.length / itemsPerPage)}
          onPageChange={handlePageChange}
          marginPagesDisplayed={2}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="active"
          shape="rounded"
        />
      </div>

      {/* <div className="flex justify-center items-center px-2 mr-3">
        <div className="w-full min-h-[45vh] mt-3  bg-white rounded">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={15}
            disableSelectionOnClick
            autoHeight
          />
        </div>
      </div> */}
    </div>
    //  )}
    //  </>
  );
};

export default AllCompletes;
