import axios from 'axios';
import { server } from '../../server';



// get All Customers of an Admin
export const getAllCustomersAdmin = () => async (dispatch) => {
  try {
    dispatch({
      type: 'getAllCustomersAdminRequest',
    });

    const { data } = await axios.get(
      `${server}/customer/get-all-customer`
    );
    dispatch({
      type: 'getAllCustomersAdminSuccess',
      payload: data.customers,
    });
  } catch (error) {
    dispatch({
      type: 'getAllCustomersAdminFailed',
      payload: error.response.data.message,
    });
  }
};



// delete customer of an admin
export const deleteCustomer = (id) => async (dispatch) => {
  try {
    dispatch({
      type: 'deleteCustomerAdminRequest',
    });

    const { data } = await axios.delete(
      `${server}/customer/delete-customer-admin/${id}`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: 'deleteCustomerAdminSuccess',
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: 'deleteCustomerAdminFailed',
      payload: error.response.data.message,
    });
  }
};
