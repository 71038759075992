import React from 'react';
import AdminHeader from '../components/Layout/AdminHeader';
import AdminSideBar from '../components/Admin/Layout/AdminSideBar';
import AllContractAmounts from '../components/Admin/AllContractAmounts';

const AdminContractAmounts = () => {
  return (
    <div>
      <AdminHeader />
      <div className="flex items-start justify-between w-full">
        <div className="w-[70px] lg:w-[320px] xl:w-[290px] md:w-[60px]">
          <AdminSideBar active={17} />
        </div>
        <AllContractAmounts />
      </div>
    </div>
  );
};

export default AdminContractAmounts;
