import axios from 'axios';
import { server } from '../../server';

// create brand
export const createbrand = (name) => async (dispatch) => {
  try {
    dispatch({
      type: 'brandCreateRequest',
    });

    // const config = { headers: { "Content-Type": "multipart/form-data" } };

    const { data } = await axios.post(
      `${server}/brand/create-brand`,
      name
      // config,
    );
    dispatch({
      type: 'brandCreateSuccess',
      payload: data.brand,
    });
  } catch (error) {
    dispatch({
      type: 'brandCreateFail',
      payload: error.response.data.message,
    });
  }
};

// get all brands by id
// export const getBrandId = (id) => async (dispatch) => {
//     try {
//       dispatch({
//         type: "getBrandsIdRequest",
//       });

//       const { data } = await axios.get(
//         `${server}/brand/get-brandId/${id}`
//       );
//       dispatch({
//         type: "getBrandsIdSuccess",
//         payload: data.brand,
//       });
//     } catch (error) {
//       dispatch({
//         type: "getBrandsIdFailed",
//         payload: error.response.data.message,
//       });
//     }
//   };

// delete brand
export const deleteBrand = (id) => async (dispatch) => {
  try {
    dispatch({
      type: 'deleteBrandRequest',
    });

    const { data } = await axios.delete(`${server}/brand/delete-brand/${id}`, {
      withCredentials: true,
    });

    dispatch({
      type: 'deleteBrandSuccess',
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: 'deleteBrandFailed',
      payload: error.response.data.message,
    });
  }
};

// get all brands
export const getAllBrands = () => async (dispatch) => {
  try {
    dispatch({
      type: 'getAllBrandsRequest',
    });

    const { data } = await axios.get(`${server}/brand/admin-all-brands`);
    dispatch({
      type: 'getAllBrandsSuccess',
      payload: data.brands,
    });
  } catch (error) {
    dispatch({
      type: 'getAllBrandsFailed',
      payload: error.response.data.message,
    });
  }
};
