import React from 'react';
import { BsChatRightText, BsCheck2Circle, BsXCircle } from 'react-icons/bs';
import { FiPackage } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { BiChip, BiRuler } from 'react-icons/bi';
import {
  AiOutlineAntDesign,
  AiOutlineAppstoreAdd,
  AiOutlineBuild,
  AiOutlineStop,
} from 'react-icons/ai';
import { FaRegHandPaper, FaRegNewspaper, FaUsers } from 'react-icons/fa';
import { useSelector } from 'react-redux';
// import { getAllSellers } from '../../../redux/actions/sellers';
import { useTranslation } from 'react-i18next';

const DashboardSideBar = ({ active }) => {
  // const dispatch = useDispatch();
  const { t } = useTranslation();
  const { seller } = useSelector((state) => state.seller);

  // useEffect(() => {
  //   dispatch(getAllSellers());
  // }, [dispatch]);

  return (
    <div className="fixed flex flex-1 overflow-y-auto overflow-hidden min-h-0 rtl:right-0  flex-col mt-[60px] left-0 w-14 hover:w-60 lg:w-60 p-3 bg-[#404041]  h-full text-white transition-transform duration-200 border-none z-40 sidebar">
      <div className=" overflow-x-hidden snap-start">
        {/* <div className="fixed  overflow-y-auto rtl:right-0 flex flex-col top-[60px] left-0 w-14 hover:w-60 lg:w-60 p-3 bg-[#404041]  h-full text-white transition-transform duration-200 border-none z-10 sidebar">
       <div className="overflow-y-auto overflow-x-hidden flex flex-col justify-between"> */}
        {seller && seller.role === 'Recieptionist' ? (
          <Link to="/dashbord/createCustomerSeller">
            <div
              className={`w-full flex items-center mt-1 p-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                active === 12 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
              }`}
            >
              <div className="inline-flex justify-center items-center">
                <BsXCircle
                  size={22}
                  color={`${active === 12 ? '#fff' : '#F4AA8F'}`}
                  className="items-center pr-1"
                />
                <h5
                  className={`px-2  tracking-wide truncate text-[16px] font-[400]  ${
                    active === 12 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                  }`}
                >
                  {t('main.add_customer')}
                </h5>
              </div>
            </div>
          </Link>
        ) : (
          <>
            <Link to="/dashboard">
              <div
                className={`w-full flex items-center mt-1 p-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                  active === 1 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                }`}
              >
                <div className="inline-flex justify-center items-center">
                  <FiPackage
                    size={22}
                    color={`${active === 1 ? '#fff' : '#F4AA8F'}`}
                    className="items-center pr-1"
                  />
                  <h5
                    className={`px-2 tracking-wide truncate text-[16px] font-[400]  ${
                      active === 1 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                    }`}
                  >
                    {t('main.dashboard')}
                  </h5>
                </div>
              </div>
            </Link>
            {/* {seller && seller.role === 'BranchAdmin' ? null : (
              <>
                <Link to="/dashboard/register/customer">
                  <div
                    className={`w-full flex items-center mt-2 p-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                      active === 2 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                    }`}
                  >
                    <div className="inline-flex justify-center items-center">
                      <AiOutlineUserAdd
                        size={22}
                        color={`${active === 2 ? '#fff' : '#F4AA8F'}`}
                        className="items-center pr-1"
                      />
                      <h5
                        className={`px-2  tracking-wide truncate text-[16px] font-[400]  ${
                          active === 2 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                        }`}
                      >
                        {t('main.add_customer')}
                      </h5>
                    </div>
                  </div>
                </Link>
               
              </>
            )} */}

            {seller && seller.role === 'BranchAdmin' ? (
              <Link to="/dashboard/Engineers">
                <div
                  className={`w-full flex items-center mt-1 px-1 py-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                    active === 2 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                  }`}
                >
                  <div className="inline-flex justify-center items-center">
                    <BiChip
                      size={22}
                      color={`${active === 2 ? '#fff' : '#F4AA8F'}`}
                      className="items-center pr-1"
                    />
                    <h5
                      className={`px-2 tracking-wide truncate text-[16px] font-[400]  ${
                        active === 2 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                      }`}
                    >
                      {t('admin.all_engineer')}
                    </h5>
                  </div>
                </div>
              </Link>
            ) : null}

            <Link
              to="/dashboard/customers"
              state={{ All: 'All', sellerId: '' }}
            >
              <div
                className={`w-full flex items-center mt-1 p-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                  active === 3 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                }`}
              >
                <div className="inline-flex justify-center items-center">
                  <FaUsers
                    size={22}
                    color={`${active === 3 ? '#fff' : '#F4AA8F'}`}
                    className="items-center pr-1"
                  />
                  <h5
                    className={`px-2  tracking-wide truncate text-[16px] font-[400]  ${
                      active === 3 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                    }`}
                  >
                    {t('main.customers')}
                  </h5>
                </div>
              </div>
            </Link>

            <Link
              to="/dashboard/measurements"
              state={{ All: 'All', sellerId: '' }}
            >
              <div
                className={`w-full flex items-center mt-1 p-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                  active === 4 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                }`}
              >
                <div className="inline-flex justify-center items-center">
                  <BiRuler
                    size={22}
                    color={`${active === 4 ? '#fff' : '#F4AA8F'}`}
                    className="items-center pr-1"
                  />
                  <h5
                    className={`px-2 tracking-wide truncate text-[16px] font-[400]  ${
                      active === 4 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                    }`}
                  >
                    {t('main.measurements')}
                  </h5>
                </div>
              </div>
            </Link>
            <Link to="/dashboard/design" state={{ All: 'All', sellerId: '' }}>
              <div
                className={`w-full flex items-center mt-1 p-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                  active === 5 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                }`}
              >
                <div className="inline-flex justify-center items-center">
                  <AiOutlineAntDesign
                    size={22}
                    color={`${active === 5 ? '#fff' : '#F4AA8F'}`}
                    className="items-center pr-1"
                  />
                  <h5
                    className={`px-2  tracking-wide truncate text-[16px] font-[400]  ${
                      active === 5 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                    }`}
                  >
                    {t('main.design')}
                  </h5>
                </div>
              </div>
            </Link>
            <Link
              to="/dashboard/discussion"
              state={{ All: 'All', sellerId: '' }}
            >
              <div
                className={`w-full flex items-center mt-1 p-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                  active === 6 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                }`}
              >
                <div className="inline-flex justify-center items-center">
                  <BsChatRightText
                    size={22}
                    color={`${active === 6 ? '#fff' : '#F4AA8F'}`}
                    className="items-center pr-1"
                  />
                  <h5
                    className={`px-2 tracking-wide truncate text-[16px] font-[400]  ${
                      active === 6 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                    }`}
                  >
                    {t('main.discussion')}
                  </h5>
                </div>
              </div>
            </Link>
            <Link to="/dashboard/holdings" state={{ All: 'All', sellerId: '' }}>
              <div
                className={`w-full flex items-center mt-1 p-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                  active === 7 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                }`}
              >
                <div className="inline-flex justify-center items-center">
                  <FaRegHandPaper
                    size={22}
                    color={`${active === 7 ? '#fff' : '#F4AA8F'}`}
                    className="items-center pr-1"
                  />
                  <h5
                    className={`px-2  tracking-wide truncate text-[16px] font-[400]  ${
                      active === 7 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                    }`}
                  >
                    {t('main.holding')}
                  </h5>
                </div>
              </div>
            </Link>
            <Link
              to="/dashboard/contracts"
              state={{ All: 'All', sellerId: '' }}
            >
              <div
                className={`w-full flex items-center mt-1 p-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                  active === 8 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                }`}
              >
                <div className="inline-flex justify-center items-center">
                  <FaRegNewspaper
                    size={22}
                    color={`${active === 8 ? '#fff' : '#F4AA8F'}`}
                    className="items-center pr-1"
                  />
                  <h5
                    className={`px-2  tracking-wide truncate text-[16px] font-[400]  ${
                      active === 8 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                    }`}
                  >
                    {t('main.contract')}
                  </h5>
                </div>
              </div>
            </Link>

            <Link
              to="/dashboard/stopOrder"
              state={{ All: 'All', sellerId: '' }}
            >
              <div
                className={`w-full flex items-center mt-1 p-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                  active === 9 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                }`}
              >
                <div className="inline-flex justify-center items-center">
                  <AiOutlineStop
                    size={22}
                    color={`${active === 9 ? '#fff' : '#F4AA8F'}`}
                    className="items-center pr-1"
                  />
                  <h5
                    className={`px-2  tracking-wide truncate text-[16px] font-[400]  ${
                      active === 9 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                    }`}
                  >
                    {t('main.stop_order')}
                  </h5>
                </div>
              </div>
            </Link>

            <Link to="/dashboard/store" state={{ All: 'All', sellerId: '' }}>
              <div
                className={`w-full flex items-center mt-1 p-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                  active === 10 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                }`}
              >
                <div className="inline-flex justify-center items-center">
                  <AiOutlineAppstoreAdd
                    size={22}
                    color={`${active === 10 ? '#fff' : '#F4AA8F'}`}
                    className="items-center pr-1"
                  />
                  <h5
                    className={`px-2  tracking-wide truncate text-[16px] font-[400]  ${
                      active === 10 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                    }`}
                  >
                    {t('main.store')}
                  </h5>
                </div>
              </div>
            </Link>

            <Link
              to="/dashboard/implementations"
              state={{ All: 'All', sellerId: '' }}
            >
              <div
                className={`w-full flex items-center mt-1 p-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                  active === 11 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                }`}
              >
                <div className="inline-flex justify-center items-center">
                  <AiOutlineBuild
                    size={22}
                    color={`${active === 11 ? '#fff' : '#F4AA8F'}`}
                    className="items-center pr-1"
                  />
                  <h5
                    className={`px-2  tracking-wide truncate text-[16px] font-[400]  ${
                      active === 11 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                    }`}
                  >
                    {t('main.implementations')}
                  </h5>
                </div>
              </div>
            </Link>
            <Link
              to="/dashboard/completes"
              state={{ All: 'All', sellerId: '' }}
            >
              <div
                className={`w-full flex items-center mt-1 p-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                  active === 12 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                }`}
              >
                <div className="inline-flex justify-center items-center">
                  <BsCheck2Circle
                    size={22}
                    color={`${active === 12 ? '#fff' : '#F4AA8F'}`}
                    className="items-center pr-1"
                  />
                  <h5
                    className={`px-2 tracking-wide truncate text-[16px] font-[400]  ${
                      active === 12 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                    }`}
                  >
                    {t('main.completed')}
                  </h5>
                </div>
              </div>
            </Link>

            <Link to="/dashboard/canceled" state={{ All: 'All', sellerId: '' }}>
              <div
                className={`w-full flex items-center mt-1 p-2  cursor-pointer hover:bg-[#222222] hover:rounded ${
                  active === 13 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                }`}
              >
                <div className="inline-flex justify-center items-center">
                  <BsXCircle
                    size={22}
                    color={`${active === 13 ? '#fff' : '#F4AA8F'}`}
                    className="items-center pr-1"
                  />
                  <h5
                    className={`px-2  tracking-wide truncate text-[16px] font-[400]  ${
                      active === 13 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                    }`}
                  >
                    {t('main.canceled')}
                  </h5>
                </div>
              </div>
            </Link>
            <Link to="/dashboard/storage" state={{ All: 'All', sellerId: '' }}>
              <div
                className={`w-full flex items-center mt-1 p-2 mb-16 cursor-pointer hover:bg-[#222222] hover:rounded ${
                  active === 14 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                }`}
              >
                <div className="inline-flex justify-center items-center">
                  <AiOutlineAppstoreAdd
                    size={22}
                    color={`${active === 14 ? '#fff' : '#F4AA8F'}`}
                    className="items-center pr-1"
                  />
                  <h5
                    className={`px-2 tracking-wide truncate text-[16px] font-[400]  ${
                      active === 14 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                    }`}
                  >
                    {t('main.mainstorage')}
                  </h5>
                </div>
              </div>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default DashboardSideBar;
