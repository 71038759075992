import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
};

export const storageReducer = createReducer(initialState, {
  orderCreateRequest: (state) => {
    state.isLoading = true;
  },
  orderCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.storage = action.payload;
    state.success = true;
  },
  orderCreateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  //get storage by ID
//   getBranchIdRequest: (state) => {
//     state.isLoading = true;
//   },
//   getBranchIdSuccess: (state, action) => {
//     //state.isBranch = true;
//     state.isLoading = false;
//     state.storage = action.payload;
//   },
//   getBranchIdFailed: (state, action) => {
//     state.isLoading = false;
//     state.error = action.payload;
//   },

  // get all Storages
  getAllStoragesRequest: (state) => {
    state.isLoading = true;
  },
  getAllStoragesSuccess: (state, action) => {
    state.isLoading = false;
    state.storages = action.payload;
  },
  getAllStoragesFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
