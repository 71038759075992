import React from 'react';
import { RxCross1 } from 'react-icons/rx';
import styles from '../../styles/styles';
import { useDispatch, useSelector } from 'react-redux';
import { removeFromNotification } from '../../redux/actions/notufication';
import { useTranslation } from 'react-i18next';

const UpdateCustomers = ({ setOpenNote }) => {
  const { notification } = useSelector((state) => state.notification);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const removeFromCartHandler = (data) => {
    dispatch(removeFromNotification(data));
  };

  return (
    <div className="fixed top-0   w-full bg-[#0000004b] h-screen z-10">
      <div className="fixed top-0   rtl:left-0 ltr:right-0 h-full w-[80%] 800px:w-[25%] bg-white flex flex-col overflow-y-scroll justify-between shadow-sm">
        {notification && notification.length === 0 ? (
          <div className="w-full h-screen flex items-center justify-center">
            <div className="flex w-full justify-end pt-5 pr-5 fixed top-1 ltr:right-3   rtl:left-6">
              <RxCross1
                size={25}
                className="cursor-pointer"
                onClick={() => setOpenNote(false)}
              />
            </div>
            <h5>{t('customer.notification_empty')}</h5>
          </div>
        ) : (
          <>
            <div>
              <div className="flex w-full justify-end px-2 pt-5 pr-5 ltr:right-3 rtl:left-4">
                <RxCross1
                  size={25}
                  className="cursor-pointer"
                  onClick={() => setOpenNote(false)}
                />
              </div>
              {/* Item length */}

              <div className={`${styles.noramlFlex} px-2 py-2`}>
                {/* <IoBagHandleOutline size={25} /> */}
                <h5 className="pl-2 text-[20px] font-[500]">
                 ({notification && notification.length}) {t('main.customers')}  {t('customer.not_updated')}
                </h5>
              </div>

              {/* cart Single Items */}
              <br />
              <div className="w-full border-t">
                {notification &&
                  notification.map((i, index) => (
                    <CartSingle
                      key={index}
                      data={i}
                      // quantityChangeHandler={quantityChangeHandler}
                      removeFromCartHandler={removeFromCartHandler}
                    />
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const CartSingle = ({ data, removeFromCartHandler }) => {
  const { t } = useTranslation();
  return (
    <div className="border-b px-3 py-2">
      <div className="flex justify-end items-center">
        <RxCross1
          size={18}
          className="cursor-pointer"
          onClick={() => removeFromCartHandler(data)}
        />
      </div>
      <div className="w-full flex items-center">
        <div className="pl-[5px]">
          <h1>{data.name}</h1>

          <h4 className="font-[500] text-[1٤px] pt-[3px] text-[#d02222] font-Roboto">
          {t('customer.not_updated_customer')}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default UpdateCustomers;
