import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedAdminRoute = ({ children }) => {
  const { loading, isAuthenticated } = useSelector((state) => state.user);
  if (loading === false) {
    if (!isAuthenticated) {
      return <Navigate to="/login" replace />;
    } 
    // else if (user.role === 'StorageAdmin') {
    //   return <Navigate to="/admin/Storage" replace />;
    // }

    // else if (user.role !== 'MiniAdmin') {
    //   return <Navigate to="/admin/summary" replace />;
    // }
    return children;
  }
};

export default ProtectedAdminRoute;
