import axios from 'axios';
import { server } from '../../server';

// get all sellers --- admin
export const getAllSellers = () => async (dispatch) => {
  try {
    dispatch({
      type: 'getAllSellersRequest',
    });

    const { data } = await axios.get(
      `${server}/engineering/admin-all-sellers`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: 'getAllSellersSuccess',
      payload: data.sellers,
    });
  } catch (error) {
    dispatch({
      type: 'getAllSellerFailed',
      //   payload: error.response.data.message,
    });
  }
};

//load seller
// export const getBranchSeller = (id) => async (dispatch) => {
//   try {
//     dispatch({
//       type: 'LoadSellerRequest',
//     });
//     const { data } = await axios.get(`${server}/engineering/getAllEngineer-branch/${id}`, {
//       withCredentials: true,
//     });
//     dispatch({
//       type: 'LoadSellerSuccess',
//       payload: data.engineer,
//     });
//   } catch (error) {
//     dispatch({
//       type: 'LoadSellerFail',
//       payload: error.response.data.message,
//     });
//   }
// };
