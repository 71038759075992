import React from 'react'
import AdminHeader from '../components/Layout/AdminHeader'
import AdminSideBar from '../components/Admin/Layout/AdminSideBar'
import AllUsers from "../components/Admin/AllUsers";

const AdminDashboardUsers = () => {
  return (
    <div>
    <AdminHeader />
    <div className="flex items-start justify-between w-full">
      <div className="w-[70px] lg:w-[320px] xl:w-[290px] md:w-[60px]">
        <AdminSideBar active={5} />
      </div>
      <AllUsers />
    </div>
  </div>


    
  )
}

export default AdminDashboardUsers