import React from 'react'
import Header from "../components/Layout/Header";
import Hero from "../components/Route/Hero/Hero";

import Footer from "../components/Layout/Footer";



const HomePage = () => {
  return (
    <div className="min-h-screen overflow-hidden bg-gray-50">
        <Header activeHeading={1} />
        <Hero />
       
        <Footer />
    </div>
  )
}

export default HomePage