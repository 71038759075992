import React, { useEffect } from 'react';
import DashboardHeader from '../../components/Engineering/Layout/DashboardHeader';
import DashboardSideBar from '../../components/Engineering/Layout/DashboardSideBar';
import DashboardHero from '../../components/Engineering/DashboardHero';

import { getAllCustomersEng } from '../../redux/actions/customer';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ShopDashboardPage = () => {
  // const { customers } = useSelector((state) => state.customers);
  const { seller } = useSelector((state) => state.seller);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [allCustomers, setAllCustomers] = useState([]);
  // const [updateDate, setUpdateDate] = useState([]);
  // const [dated, setDated] = useState(new Date());
  // const [userName, setUserName] = useState('');

  useEffect(() => {
    if (seller.role === 'Recieptionist') {
      navigate(`/dashbord/createCustomerSeller`);
    }
    // else if (seller.branchId !== engBranchId) {
    //   navigate(`/engineer-login/${engBranchId}`);
    // }
  }, [seller, navigate]);

  useEffect(() => {
    dispatch(getAllCustomersEng(seller._id));
  }, [dispatch, seller._id]);

  // const addToCartHandler = (data, id) => {

  // };

  return (
    <div>
      <DashboardHeader />
      <div className="flex items-start justify-between w-full">
        <div className="w-[70px] lg:w-[320px] xl:w-[290px] md:w-[60px]">
          <DashboardSideBar active={1} />
        </div>
        <DashboardHero />
      </div>
    </div>
  );
};

export default ShopDashboardPage;
