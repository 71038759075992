import React, { useEffect, useState } from 'react';
// import { signal } from '@preact/signals-react';
import { AiOutlinePlusCircle, AiOutlineSnippets } from 'react-icons/ai';
import { BsTrash } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  deleteCustomerLevel,
  getAllCustomersEng,
  updatCustomerLevel,
} from '../../redux/actions/customer';
import axios from 'axios';
import { backend_url, server } from '../../server';
import { toast } from 'react-toastify';
import { getAllBrands } from '../../redux/actions/brand';
import { useTranslation } from 'react-i18next';
import { getCurrentTime } from '../../utils/timeConvertor';

const CreateCustomer = () => {
  const { t } = useTranslation();
  const { seller } = useSelector((state) => state.seller);
  const { brands } = useSelector((state) => state.brands);
  const { customers } = useSelector((state) => state.customers);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(false);
  const { id: customerId } = useParams();
  const [selectBrands, setSelectBrands] = useState([]);
  const [customerBrands, setCustomerBrands] = useState([]);
  const [tempAmounts, setTempAmounts] = useState([]);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');

  const [phone, setPhone] = useState('');
  const [status, setStatus] = useState('');
  const [statusLevel, setStatusLevel] = useState([]);
  const [customerLevels, setCustomerLevels] = useState('');
  const [allComments, setAllComments] = useState([]);
  const [comment, setComment] = useState('');
  const [reason, setReason] = useState('');
  const [reasonDetail, setReasonDetail] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);
  const [noties, setNoties] = useState('');
  const [color, setColor] = useState('');
  const [image, setImage] = useState();
  const [fileNo, setFileNo] = useState();

  const [selectLevel, setSelectLevel] = useState([]);
  const [selectedCustomerLevels, setSelectedCustomerLevels] = useState([]);
  const [checkedCanceled, setCheckedCanceled] = useState(false);

  const cancelList = ['COST', 'TIME', 'OTHERS'];
  const levels = [
    { name: 'Measurements', color: '#137568' },
    { name: 'Design', color: '#C27664' },
    { name: 'Discussion', color: '#635985' },
    { name: 'Hold', color: '#F6635C' },
    { name: 'Contract', color: '#F2921D' },
    { name: 'Stop Orders', color: '#C51605' },
    { name: 'Storage', color: '#137568' },
    { name: 'Implementation', color: '#0F6292' },
    { name: 'Complete', color: '#539165' },
    { name: 'Canceled', color: '#C51605' },
  ];

  useEffect(() => {
    const isItemExists =
      customers && customers.find((i) => i._id === customerId);
    if (isItemExists) {
      setUpdate(true);
      setName(isItemExists.name);
      setAddress(isItemExists.address);
      setPhone(isItemExists.phone);
      setSelectBrands(isItemExists?.selectBrands);
      setStatusLevel(isItemExists?.levels);
      setAllComments(isItemExists?.comments);
      setImage(`${backend_url}${isItemExists.image}`);
      setReason(isItemExists.reason);
      setTotalPrice(isItemExists?.totalPrice);
      setReasonDetail(isItemExists.reasonDetail);
      setNoties(isItemExists.noties);
      setFileNo(isItemExists?.fileNo);

      const tmpBrands = brands?.map((brand) => {
        const item = {
          name: brand?.name,
          selected: false,
          amount: 0,
          updatedAt: '',
        };
        isItemExists.selectBrands?.forEach((b) => {
          if (b?.name === brand?.name) {
            item.amount = b?.amount;
            item.selected = true;
          }
        });
        return item;
      });
      const amounts = tmpBrands?.map((t) => t.amount);
      setTempAmounts(amounts);
      setCustomerBrands(tmpBrands);

      const tmpLevels = levels?.map((level) => {
        const item = {
          status: level?.name,
          color: level.color,
          selected: false,
          updatedAt: '',
        };
        isItemExists.levels?.forEach((b) => {
          if (b?.status === level?.name) {
            item.color = b?.color;
            item.selected = true;
            item.updatedAt = b?.updatedAt;
          }
        });
        return item;
      });

      setSelectLevel(tmpLevels);
    }
  }, [customers, seller.role, customerId, brands]);

  useEffect(() => {
    dispatch(getAllCustomersEng(seller._id));
    dispatch(getAllBrands());
  }, [dispatch, customerId, seller._id]);

  //   useEffect(() => {
  //     const isItemExists =
  //       customers && customers.find((i) => i._id === customerId);
  //     if (isItemExists) {
  //       //customer levels

  //       const item = { selected: false };
  //       isItemExists.levels?.forEach((b) => {
  //         if (b?.status === 'Stop Orders') {
  //           item.selected = b?.selected;
  //         }
  //       });

  //       setCustomerLevels(item.selected);
  //       // console.log(customerLevels);
  //       // };
  //     }
  //   }, [customers, customerId, customerLevels, brands]);

  //levels
  const handleCheckLevels = (event, index) => {
    const value = event.target.value;
    const createdAt = getCurrentTime();
    const isChecked = event?.target.checked;

    selectLevel[index] = {
      ...selectLevel[index],
      selected: isChecked,
      updatedAt: createdAt,
    };

    setStatus(value);
    if (value === 'Canceled') {
      setCheckedCanceled(isChecked);
      setReason('');
      setReasonDetail('');
    }
    setSelectedCustomerLevels(selectLevel.filter((t) => t.selected));
  };

  //Brands

  const handleCheck = (event, index) => {
    const isChecked = event?.target.checked;
    customerBrands[index] = {
      ...customerBrands[index],
      selected: isChecked,
      amount: 0,
    };
    setSelectBrands(customerBrands.filter((t) => t.selected));

    // if(isChecked){
    //   setTempAmounts(customerBrands.filter((t) => t.selected)?.map((t) => t.amount));
    //   console.log(tempAmounts)
    // }
    // else {
    //   const tmpBrands = brands?.map((brand) => {
    //     const item = { name: brand?.name, selected: false, amount: 0 };
    //     selectBrands?.forEach((b) => {
    //       if (b?.name === brand?.name) {
    //         item.amount = b?.amount;
    //         item.selected = true;
    //       }
    //     });
    //     return item;
    //   });
    //   const amounts = tmpBrands?.map((t) => t.amount);
    //   setTempAmounts(amounts);
    //   // setCustomerBrands(tmpBrands);
    // }

    // console.log(tempAmounts);
    // setTotalPrice(
    //   selectBrands
    //     ?.filter((item1) => item1.amount > 0)
    //     .reduce((acc, current) => acc + current, 0)
    // );
    // console.log(selectBrands, customerBrands);
    // else {
    //   //Remove unchecked item from checkList
    //   const filteredList = selectBrands.filter((item) => item.name !== value);
    //   setSelectBrands(filteredList);
    //   // console.log(selectBrands);
    // }
  };

  const handleCheckStatus = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
    setStatus(value);

    if (isChecked) {
      levels.filter((item) =>
        item.name === value ? setColor(item.color) : null
      );
    }
  };

  // Generate string of checked items
  const checkedItems = selectBrands?.map((item1) => item1.name).length
    ? selectBrands
        .map((item1) => item1.name)
        .reduce((total, item) => {
          return total + ', ' + item;
        })
    : '';

  var isChecked = (item) =>
    selectBrands?.map((item1) => item1?.name).includes(item?.name)
      ? 'checked-item'
      : 'not-checked-item';

  //   const handleSubmitLevel = async () => {
  //     if (status === '') {
  //       //toast.error('Please select level!');
  //     } else {
  //       dispatch(updatCustomerLevel(customerId, status, color, reasonDetail));
  //     }
  //   };

  //   const handleDelete = async (item) => {
  //     const id = item._id;
  //     await axios.delete(`${server}/customer/delete-customer-level/${item}`, {
  //       withCredentials: true,
  //     });
  //     // const id = item._id;
  //     // dispatch(deleteCustomerLevel(id));
  //   };

  const handleImage = async (e) => {
    // e.preventDefault();
    const file = e.target.files[0];
    setImage(URL.createObjectURL(file));
    const formData = new FormData();
    formData.append('image', file);
    if (update) {
      await axios
        .put(`${server}/customer/add-customer-image/${customerId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        })
        .then((res) => {
          toast.success('Image add successfully!');
          dispatch(getAllCustomersEng(seller._id));
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      const file = e.target.files[0];
      setImage(file);
      setUpdate(false);
    }
  };

  const handleImageUpdate = async (e) => {
    // e.preventDefault();
    const file = e.target.files[0];
    setImage(URL.createObjectURL(file));
    const formData = new FormData();
    formData.append('image', file);

    await axios
      .put(`${server}/customer/update-customer-image/${customerId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      })
      .then((res) => {
        toast.success('Image update successfully!');
        dispatch(getAllCustomersEng(seller._id));
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });

    setImage(file);
    setUpdate(false);
  };

  const handleAmount = (index, newValue) => {
    const tmp = [...tempAmounts];
    tmp[index] = +newValue;
    setTempAmounts(tmp);

    // const current = { ...updatedItems[index] };
    // current.amount = newValue;

    // setCustomerBrands(updatedItems);
  };

  const handleSubmitComments = async (e) => {
    e.preventDefault();
    const createdAt = getCurrentTime();
    await axios
      .put(
        `${server}/customer/update-customer-comments/${customerId}`,
        {
          comment,
          name: seller.name,
          createdAt,
        },

        {
          // headers: {
          //   'Content-Type': 'application/json',
          // },
          withCredentials: true,
        }
      )
      .then((res) => {
        toast.success('Comment added succesfully!');
        //  window.location.reload();
        dispatch(getAllCustomersEng(seller._id));

        setComment('');
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // handleTotalAmount()
    const selected = customerBrands?.map(
      (item, index) => (item.amount = tempAmounts[index])
    );
    // setTempAmounts(
    //   customerBrands.filter((t) => t.selected)?.map((t) => t.amount)
    // );
    const totalAmount = customerBrands
      .filter((t) => t?.selected)
      ?.map((t) => t?.amount)
      ?.filter((item1) => item1 > 0)
      .reduce((acc, current) => acc + current, 0);

    // setCustomerBrands(selected);
    // console.log(selected);
    if (customerId) {
      await axios
        .put(
          `${server}/customer/update-customer-info/${customerId}`,
          {
            name,
            address,
            fileNo,
            phone,
            branchId: seller.branchId,
            selectBrands: customerBrands?.filter((t) => t.selected),
            levels: selectLevel?.filter((t) => t.selected),
            reasonDetail,
            reason,
            totalPrice: totalAmount,
            noties,
          },
          { withCredentials: true }
        )
        .then((res) => {
          //   handleSubmitLevel();
          toast.success('Customer info updated succesfully!');

          navigate('/dashboard/customers');
          dispatch(getAllCustomersEng(seller._id));
          // window.location.reload(true);
          //
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
    // else {
    //   setImage(imageUrl);
    //   const customerLevel = {
    //     status,
    //     color,
    //     reasonDetail,
    //   };
    //   const newForm = {
    //     name,
    //     address,
    //     phone,
    //     branchId: seller.branchId,
    //     selectBrands,
    //     reason,
    //     totalPrice,
    //     noties,
    //     image,
    //     customerLevel,
    //     engineeringId: seller._id,
    //     engineering: seller.name,
    //   };

    //   // const newForm = new FormData();
    //   // newForm.append('image', image);
    //   // newForm.append('name', name);
    //   // newForm.append('address', address);
    //   // newForm.append('phone', phone);
    //   // newForm.append('brands', brands);
    //   // newForm.append('reason', reason);
    //   // newForm.append('noties', noties);
    //   // newForm.append('image', image);
    //   // newForm.append('customerLevel', customerLevel);
    //   // newForm.append('engineeringId', seller._id);
    //   // newForm.append('engineering', seller.name);

    //   dispatch(createCustomer(newForm));

    //   navigate('/dashboard/customers');
    //   dispatch(getAllCustomersEng(seller._id));
    //   //handleSubmitLevel();
    // }
  };

  return (
    <div className="container">
      <div className="flex justify-between mr-3 items-center border-b p-2 border-gray-200">
        <p className="px-3 md:px-2 text-[18px] font-[600] mt-[70px]">
          {t('main.add_customer')}
        </p>
        <p className="px-3 text-[16px] font-[500] mt-[70px]"></p>
      </div>

      <div className="container ">
        {/* create product form */}
        <form onSubmit={handleSubmit} className="">
          <div className=" grid p-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pt-2 gap-4">
            <div className="-mx-3 mb-6 px-4 w-full">
              <div className="flex flex-wrap justify-center items-center -mx-3 mb-6">
                <div className="w-full  px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                    {t('customer.name')}
                  </label>
                  <input
                    className="appearance-none block w-full bg-[#F1F2F7] text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-[#404041]"
                    id="name"
                    type="text"
                    autoComplete="off"
                    required
                    // disabled
                    placeholder="Customer Name"
                    name="name"
                    value={name || ''}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full  px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                    {t('customer.phone')}
                  </label>
                  <input
                    className="appearance-none block w-full bg-[#F1F2F7] text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-[#404041]"
                    id="phone"
                    type="text"
                    autoComplete="off"
                    required
                    // disabled
                    placeholder="Customer Phone"
                    name="phone"
                    value={phone || ''}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full  px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                    {t('customer.address')}
                  </label>
                  <input
                    className="appearance-none block w-full bg-[#F1F2F7] text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-[#404041]"
                    id="address"
                    type="text"
                    autoComplete="off"
                    required
                    // disabled
                    placeholder="Customer address"
                    name="address"
                    value={address || ''}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full  px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                    {t('customer.fileNo')}
                  </label>
                  <input
                    className="appearance-none block w-full bg-[#F1F2F7] text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-[#404041]"
                    id="fileNo"
                    type="text"
                    autoComplete="off"
                    required
                    // disabled
                    placeholder="Customer file Number"
                    name="fileNo"
                    value={fileNo || ''}
                    onChange={(e) => setFileNo(e.target.value)}
                  />
                </div>
              </div>

              <div className="px-3 -mx-3 ">
                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                  {t('customer.brand')}
                </label>
                {brands?.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center w-full  justify-center px-2 py-1 mb-6 md:mb-3 lg:mb-4 xl:mb-4 border"
                  >
                    <input
                      checked={
                        customerBrands && customerBrands[index]?.selected
                        // .map((item1) => item1.name)
                        // .includes(item.name)
                        // ? true
                        // : false
                      }
                      type="checkbox"
                      value={customerBrands && customerBrands[index]?.name}
                      onChange={(e) => handleCheck(e, index)}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />

                    <label
                      className={`
                      ${isChecked(item?.name)} 
                      w-full px-2 py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300`}
                    >
                      {item?.name}
                    </label>
                    <div className="flex  items-center w-full  justify-center px-1 ">
                      <label className=" px-1 uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                        {t('admin.amount')}
                      </label>
                      <input
                        className="appearance-none block w-full bg-[#F1F2F7] text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-[#404041]"
                        id={item?.name + `${index}`}
                        type="number"
                        // required
                        disabled={
                          customerBrands && !customerBrands[index]?.selected
                        }
                        // placeholder="Amount"
                        name="amount"
                        value={
                          customerBrands && customerBrands[index]?.selected
                            ? tempAmounts[index]
                            : 0
                        }
                        // customerBrands
                        // .filter((item1) => item1.name === item?.name)

                        // .map((itemAmount) => itemAmount.amount)}
                        onChange={(e) => handleAmount(index, e.target.value)}
                      />
                    </div>
                  </div>
                ))}

                <div>
                  {t('customer.Items_selected_are')}:{'  '}
                  {`${checkedItems}`}
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mt-6">
                <div className="w-full flex items-center px-3 -mx-">
                  <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                    {t('customer.upload_image')}
                  </label>
                  <div className="w-full md:w-2/3 px-3 mb-6 md:mb-0 "></div>
                  <input
                    type="file"
                    name=""
                    id="uploadU"
                    className="hidden"
                    multiple
                    onChange={handleImageUpdate}
                  />
                  <input
                    type="file"
                    name=""
                    id="upload"
                    className="hidden"
                    multiple
                    onChange={handleImage}
                  />
                </div>
              </div>

              <div className="w-full flex items-center justify-start pb-3 flex-wrap">
                <label
                  htmlFor="upload"
                  className="mt-3 mb-3 p-3 items-center justify-center"
                >
                  <div className="w-full flex items-center mt-3 p-2 cursor-pointer bg-[#539165] rounded ">
                    <div className="inline-flex justify-center items-center">
                      <AiOutlinePlusCircle
                        size={30}
                        color="#F1F2F7"
                        className="items-center pr-1"
                      />
                      <h5
                        className={`px-2 tracking-wide truncate text-[16px] font-[400] text-[#fff]`}
                      >
                        {t('customer.add_image')}
                      </h5>
                    </div>
                  </div>
                </label>
                <label htmlFor="uploadU" className="mt-3 mb-3 p-3">
                  <div className="w-full flex items-center mt-3 p-2 cursor-pointer bg-[#0F6292] rounded ">
                    <div className="inline-flex justify-center items-center">
                      <AiOutlineSnippets
                        size={30}
                        color="#F1F2F7"
                        className="items-center pr-1"
                      />
                      <h5
                        className={`px-2 tracking-wide truncate text-[16px] font-[400] text-[#fff]`}
                      >
                        {t('customer.update_image')}
                      </h5>
                    </div>
                  </div>
                </label>
                {image && (
                  <img
                    src={update ? image : URL.createObjectURL(image)}
                    alt=""
                    className="h-full w-full object-cover"
                  />
                )}
              </div>
            </div>

            {/* Second div */}
            <div className="flex-wrap -mx-3 mb-2">
              <div className=" w-full  px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                  {t('customer.status')}
                </label>

                <div className=" px-2 -mx-3">
                  {levels &&
                    levels.map((item, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between w-full  px-3 mb-3 md:mb-0"
                      >
                        <div className="flex items-center w-full sm:w-full md:w-full lg:w-full xl:w-2/3 justify-center px-3 py-1 mb-3 md:mb-3 lg:mb-4 xl:mb-4 border">
                          <input
                            id={item.name}
                            disabled={
                              seller.role !== 'BranchAdmin'
                                ? statusLevel &&
                                  statusLevel[index]?.selected &&
                                  statusLevel[index]?.status !== 'Stop Orders'
                                  ? true
                                  : null
                                : null
                            }
                            // checked={
                            //   statusLevel
                            //     .filter((i) => i.status)
                            //     .includes(item.name)
                            //     ? true
                            //     : null
                            // }
                            // type="checkbox"
                            // value={item.name}
                            // onChange={handleCheckStatus}
                            checked={
                              selectLevel && selectLevel[index]?.selected
                              // .map((item1) => item1.name)
                              // .includes(item.name)
                              // ? true
                              // : false
                            }
                            type="checkbox"
                            value={selectLevel && selectLevel[index]?.status}
                            onChange={(e) => handleCheckLevels(e, index)}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                          />
                          <label
                            className={`${isChecked(
                              item
                            )} w-full px-3 py-1 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300`}
                          >
                            {item.name}
                          </label>
                        </div>

                        {/* <div className=" px-2 flex justify-start">
                          {statusLevel &&
                            statusLevel?.map((b) =>
                              b.selected === true ? (
                                <button
                                  className="text-[#F4AA8F] hover:text-[#fff]  appearance-none text-center block "
                                  //  onClick={handleDelete(b._id)}
                                >
                                  <BsTrash size={20} fill="#C51605" />
                                </button>
                              ) : null
                            )}
                        </div> */}

                        {/* {statusLevel &&
                        statusLevel
                          .filter((levelItem) => levelItem.status)
                          .includes('Stop Orders') ? (
                          <div className="relative px-2 flex justify-end w-full">
                            <button
                              className="text-[#F4AA8F] hover:text-[#fff]  appearance-none text-center block px-4 "
                              onClick={handleDelete(item)}
                            >
                              <BsTrash size={20} fill="#C51605" />
                            </button>
                          </div>
                        ) : null} */}
                        <div className="flex items-center w-full md:w-2/3 justify-center px-3 py-1 mb-3 md:mb-3 lg:mb-4 xl:mb-4">
                          {statusLevel?.map((i) =>
                            i.status === item.name ? i.updatedAt : null
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              {/* <div>
                {levels &&
                  levels.map((item, index) => (
                    <>
                      {statusLevel &&
                      statusLevel.filter(
                        (itemCanceled, index) => itemCanceled.status === item.status
                      ) ? (
                        <div className=" px-2 flex justify-start w-full">
                          <button
                            className="text-[#F4AA8F] hover:text-[#fff]  appearance-none text-center block px-4 "
                            // onClick={handleDelete(item._id)}
                          >
                            <BsTrash size={20} fill="#C51605" />
                          </button>
                        </div>
                      ) : null}
                    </>
                  ))}
              </div> */}

              {/* {statusLevel &&
              statusLevel.find((item, index) => item.status === 'Canceled') ? ( */}
              {/* {customers &&
              customers.find(
                (item) =>
                  item.levels[item.levels?.length - 1]?.status === 'Canceled'
              ) ? (
                <div>
                  <div className="w-full md:w-2/3 px-3 py-2 mb-4 md:mb-0 ">
                    {cancelList.map((item, index) => (
                      <div
                        key={index}
                        className="flex items-center rounded w-full md:w-2/3 justify-center px-3 py-1 mb-3 md:mb-2 lg:mb-2 xl:mb-2 border"
                      >
                        <input
                          id="bordered-radio-1"
                          checked={reason && reason === item ? true : false}
                          type="radio"
                          value={item}
                          disabled
                          name="bordered-radio"
                          // eslint-disable-next-line no-sequences
                          // onChange={handleCancel}
                          className="w-4 h-4 text-blue-600  bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        ></input>

                        <label
                          className={`${isChecked(
                            item
                          )} w-full py-2 px-2 text-sm font-medium text-gray-900 dark:text-gray-300`}
                        >
                          {item}
                        </label>
                      </div>
                    ))}
                  </div>

                  <div className="w-full md:w-2/3 px-3 mb-6 md:mb-0 ">
                    <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                      {t('customer.cancel_reason')}
                    </label>
                    <textarea
                      rows="3"
                      name="reasonDetail"
                      disabled
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Write cancel reason here..."
                      value={reasonDetail || ''}
                      // onChange={(e) => setReasonDetail(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              ) : null} */}
              {checkedCanceled ? (
                <>
                  {status && status === 'Canceled' ? (
                    <>
                      <div className="w-full  px-3 py-2 mt-3 mb-4 md:mb-0 ">
                        <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                          {t('customer.cancel_reason')}
                        </label>
                        {cancelList.map((item, index) => (
                          <div
                            key={index}
                            className="flex items-center rounded w-full md:w-2/3 justify-center px-3 py-1 mb-3 md:mb-2 lg:mb-2 xl:mb-2 border"
                          >
                            <input
                              id={index}
                              name="bordered-radio"
                              type="radio"
                              value={item}
                              // checked={reason && reason === item ? true : null}
                              // eslint-disable-next-line no-sequences
                              onChange={(e) => setReason(e.target.value)}
                              className="w-4 h-4 text-blue-600  bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            ></input>

                            <label
                              className={`${isChecked(
                                item
                              )} w-full py-2 px-2 text-sm font-medium text-gray-900 dark:text-gray-300`}
                            >
                              {item}
                            </label>
                          </div>
                        ))}
                      </div>
                      {reason && reason === 'OTHERS' ? (
                        <div className="w-full md:w-2/3 px-3 mb-6 md:mb-0 ">
                          <textarea
                            rows="3"
                            name="reasonDetail"
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Write cancel reason here..."
                            value={reasonDetail || ''}
                            onChange={(e) => setReasonDetail(e.target.value)}
                          ></textarea>
                          {/* <input
                        className="appearance-none block w-full bg-[#F1F2F7] text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-[#404041]"
                        type="text"
                        required
                        placeholder="Cancel Reason"
                        name="reason"
                        value={reasonDetail || ''}
                        onChange={(e) => setReasonDetail(e.target.value)}
                      /> */}
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </>
              ) : null}

              <div className="w-full px-3  mt-4 mb-6 md:mb-0 ">
                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                  {t('customer.total')}
                </label>
                <input
                  id="total"
                  name="totalPrice"
                  value={
                    // customerBrands && customerBrands?.filter((item, index)=>item.selected===true)
                    //         ? tempAmounts.filter((item1) => item1 > 0)
                    //         .reduce((acc, current) => acc + current, 0)
                    //         : 0
                    tempAmounts
                      ?.filter((item1) => item1 > 0)
                      .reduce((acc, current) => acc + current, 0) || ''
                  }
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Total Amount"
                  onChange={(e) => setTotalPrice(e.target.value)}
                ></input>
              </div>
              <div className="w-full px-3  mt-4 mb-6 md:mb-0 ">
                <label className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2">
                  {t('customer.note')}
                </label>
                <textarea
                  rows="5"
                  name="noties"
                  value={noties || ''}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Write noties here..."
                  onChange={(e) => setNoties(e.target.value)}
                ></textarea>
              </div>
              <div className="flex items-center justify-center w-full px-3 py-4 md:mb-0 ">
                <br />
                <div className="flex items-center justify-center w-2/3 px-4 mb-6 md:mb-0 ">
                  <input
                    type="submit"
                    value={update ? 'Update' : 'Create'}
                    className="mt-2 cursor-pointer text-[#F4AA8F] hover:text-[#fff] appearance-none bg-[#404041] hover:bg-[#222222] text-center block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-200 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>

            {/* Third div */}
            <div className=" mb-6 px-4 w-full ">
              <div className="flex-wrap mb-2">
                <label className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2">
                  {t('customer.comment')}
                </label>
                <textarea
                  rows="5"
                  name="comments"
                  value={comment || ''}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Write comment here..."
                  onChange={(e) => setComment(e.target.value)}
                ></textarea>
                <div className="flex items-center justify-end w-full px-3 ">
                  <div className="flex items-center justify-end w-1/3 py-2">
                    <button
                      // type="submit"
                      // value="Submit"
                      className="mt-2 cursor-pointer text-[#F4AA8F] hover:text-[#fff] appearance-none bg-[#404041] hover:bg-[#222222] text-center block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-200 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      onClick={handleSubmitComments}
                    >
                      submit
                    </button>
                  </div>
                </div>
              </div>
              <p className="px-3 md:px-2 text-[18px] font-[600] mt-[40px]">
                {t('customer.all_comment')}
              </p>
              <div className=" w-full border-t border-gray-200">
                <ul className="w-full mt-2 space-y-1 text-[#F4AA8F] list-disc list-inside dark:text-gray-400">
                  {allComments &&
                    allComments?.map((i, index) => (
                      <div
                        key={index}
                        className="py-2  px-2 border border-gray-200 rounded-lg "
                      >
                        <div className="flex items-center text-blue-600">
                          <svg
                            className="w-3.5 h-3.5 me-2 text-[#539165] dark:text-green-400 flex-shrink-0"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                          </svg>
                          <div className="flex items-center justify-between text-blue-600">
                            <div className=" text-blue-600">{i.name}</div>
                            <div className="px-3 text-blue-600">
                              {'('} {i.createdAt}
                              {' )'}
                            </div>
                          </div>
                        </div>
                        <ol className="ps-5 text-[#222222] mt-2 space-y-1 list-decimal list-inside">
                          {i.comment}
                        </ol>
                      </div>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateCustomer;
