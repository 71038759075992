const styles = {
  custom_container: 'w-11/12 hidden sm:block',
  heading:
    'text-[27px] text-center md:text-start font-[600] font-Roboto pb-[20px]',
  section: 'lg:w-9/12 mx-auto container md:w-10/12 sm:w-10/12',
  sectionHeader: 'mx-auto container',
  brandTitle: 'text-[28px] font-[500] font-Roboto text-[#F4AA8F]',
  productDiscountPrice: 'font-[500] text-[18px]  text-[#333] Montserrat',
  price:
    'font-[500] text-[16px] text-[#d55b45] pl-3  mt-[-4px] line-through font-Roboto',
  shop_name: 'pt-3 text-[15px] text-blue-400 pb-3',
  active_indicator: 'absolute bottom-[-27%] left-0 h-[2px] w-full bg-[#000]',
  active_indicator_reviews:
    'absolute bottom-[-27%] left-3 h-[2px] w-full bg-[#000]',
  buttonview:
    'w-[230] px-4 border-[1px] text-[#1A4442] border-[#1A4442] bg-white bg-opacity-50 h-[40px] my-3 flex items-center justify-center  cursor-pointer',
  button:
    'w-[150] border-[1px] text-[#1A4442] border-[#1A4442] bg-white bg-opacity-50 h-[40px] my-3 flex items-center justify-center  cursor-pointer',
  button_section:
    'w-[120px] bg-white h-[40px] hover:bg-[#F6F6F6] border border-[#000] my-2 mx-1 flex items-center justify-center rounded-lg cursor-pointer',
  add_product_button:
    'w-[250px] bg-black h-[40px] mt-1 flex items-center justify-center rounded-xl cursor-pointer',
  cart_button:
    'px-[20px] h-[38px] rounded-[20px] bg-[#000] flex items-center justify-center cursor-pointer',
  cart_button_text: 'text-[#fff] text-[16px] font-[600]',
  add_cart:
    'w-[180px] bg-black h-[40px] my-2  flex items-center justify-center rounded-lg cursor-pointer',
  add_wishlistt:
    'w-[120px] bg-white h-[40px] border my-2 mx-3 flex items-center justify-center rounded-lg cursor-pointer',
  add_address:
    'w-[250px] bg-[#fff] h-[40px]  border border-[#000] hover:bg-[#F6F6F6] my-1 flex items-center justify-center rounded-lg cursor-pointer',
  button_back:
    'w-[150px] bg-[#fff] h-[40px]  border border-[#000] hover:bg-[#F6F6F6] my-1 flex items-center justify-center rounded-lg cursor-pointer',
  button_update:
    'w-[150px] bg-[#1B9C85] h-[40px]  border border-[#F24C3D] hover:bg-[#F6F6F6] hover:text-[#1B9C85] my-1 flex items-center justify-center rounded-lg cursor-pointer',
  input: 'w-full border p-1 rounded-[5px]',
  cartinfo_button:
    'w-[150px] bg-black h-[40px] my-2 flex items-center justify-center rounded-xl cursor-pointer',
  activeStatus:
    'w-[10px] h-[10px] rounded-full absolute top-0 right-1 bg-[#40d132]',
  noramlFlex: 'flex items-center',
};

export default styles;
