import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Loader from '../components/Layout/Loader';

const SellerProtectedRoute = ({ children }) => {
  const engBranchId = Cookies.get('branchId');
  const { isLoading, isSeller } = useSelector((state) => state.seller);

  if (isLoading === true) {
    return <Loader />;
  } else {
    if (!isSeller) {
      return <Navigate to={`/engineer-login/${engBranchId}`} replace />;
    }
    // else if (seller.role !== 'AdminSeller') {
    //   return <Navigate to="/createCustomerSeller" replace />;
    // }

    return children;
  }
};

export default SellerProtectedRoute;
