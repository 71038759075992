import axios from 'axios';
import { server } from '../../server';

//create Branch
export const createStorage = (newStorage) => async (dispatch) => {
  try {
    dispatch({
      type: 'orderCreateRequest',
    });

    const config = { headers: { 'Content-Type': 'multipart/form-data' } };

    const { data } = await axios.post(
      `${server}/storage/create-storage`,
      newStorage,
      config
    );
    dispatch({
      type: 'orderCreateSuccess',
      payload: data.storage,
    });
  } catch (error) {
    dispatch({
      type: 'orderCreateFail',
      payload: error.response.data.message,
    });
  }
};

// get All Categories of a shop
// export const getBranchID = (id) => async (dispatch) => {
//   try {
//     dispatch({
//       type: 'getBranchIdRequest',
//     });

//     const { data } = await axios.get(`${server}/branch/get-branchId/${id}`);
//     dispatch({
//       type: 'getBranchIdSuccess',
//       payload: data.categories,
//     });
//   } catch (error) {
//     dispatch({
//       type: 'getBranchIdFailed',
//       payload: error.response.data.message,
//     });
//   }
// };

// get all Branchs
export const getAllStorages = () => async (dispatch) => {
  try {
    dispatch({
      type: 'getAllStoragesRequest',
    });
    const { data } = await axios.get(`${server}/storage/all-storage`, {
      withCredentials: true,
    });
    dispatch({
      type: 'getAllStoragesSuccess',
      payload: data.storages,
    });
  } catch (error) {
    dispatch({
      type: 'getAllStoragesFailed',
      payload: error.response.data.message,
    });
  }
};
