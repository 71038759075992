import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Layout/Footer.jsx';
import Header from '../components/Layout/Header.jsx';
import Login from '../components/Login/Login.jsx';
import { loadUser } from '../redux/actions/user.js';

const LoginPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated === true) {
      dispatch(loadUser());
      navigate('/');
      
    }
  }, []);

  return (
    <div>
      <Header activeHeading={1} />
      <Login />
      <Footer />
    </div>
  );
};

export default LoginPage;
