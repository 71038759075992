// add to cart
export const addTonotification = (data) => async (dispatch, getState) => {
  dispatch({
    type: 'addToNotification',
    payload: data,
  });

  localStorage.setItem(
    'notificationItems',
    JSON.stringify(getState().notification.notification)
  );
  return data;
};

// remove from cart
export const removeFromNotification = (data) => async (dispatch, getState) => {
  dispatch({
    type: 'removeFromNotification',
    payload: data._id,
  });
  localStorage.setItem(
    'notificationItems',
    JSON.stringify(getState().notification.notification)
  );
  return data;
};
