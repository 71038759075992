import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
};

export const brandReducer = createReducer(initialState, {
  brandCreateRequest: (state) => {
    state.isLoading = true;
  },
  brandCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.brand = action.payload;
    state.success = true;
  },
  brandCreateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  // get all brand by id
  // getAlleventsShopRequest: (state) => {
  //   state.isLoading = true;
  // },
  // getAlleventsShopSuccess: (state, action) => {
  //   state.isLoading = false;
  //   state.events = action.payload;
  // },
  // getAlleventsShopFailed: (state, action) => {
  //   state.isLoading = false;
  //   state.error = action.payload;
  // },

  // delete event of a shop
  deleteBrandRequest: (state) => {
    state.isLoading = true;
  },
  deleteBrandSuccess: (state, action) => {
    state.isLoading = false;
    state.message = action.payload;
  },
  deleteBrandFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // get all brands
  getAllBrandsRequest: (state) => {
    state.isLoading = true;
  },
  getAllBrandsSuccess: (state, action) => {
    state.isLoading = false;
    state.brands = action.payload;
  },
  getAllBrandsFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
