import axios from 'axios';
import { server } from '../../server';

// create customer
export const createCustomer = (newForm) => async (dispatch) => {
  try {
    dispatch({
      type: 'customerCreateRequest',
    });

    // const config = { headers: { 'Content-Type': 'multipart/form-data' } };

    const { data } = await axios.post(
      `${server}/customer/create-customer`,
      newForm
      // config
    );
    dispatch({
      type: 'customerCreateSuccess',
      payload: data.customer,
    });
  } catch (error) {
    dispatch({
      type: 'customerCreateFail',
      payload: error.response.data.message,
    });
  }
};

// get All Customers of an eng
export const getAllCustomersEng = (id) => async (dispatch) => {
  try {
    dispatch({
      type: 'getAllCustomersEngRequest',
    });

    const { data } = await axios.get(
      `${server}/customer/get-all-customer-eng/${id}`
    );
    dispatch({
      type: 'getAllCustomersEngSuccess',
      payload: data.customers,
    });
  } catch (error) {
    dispatch({
      type: 'getAllCustomersEngFailed',
      payload: error.response.data.message,
    });
  }
};

// update customer level
export const updatCustomerLevel =
  (id, status, color, reasonDetail) => async (dispatch) => {
    try {
      dispatch({
        type: 'updateCustomerLevelRequest',
      });
      console.log(reasonDetail);
      const { data } = await axios.put(
        `${server}/customer/update-customer-levels/${id}`,
        {
          status,
          color,
          reasonDetail,
        },
        { withCredentials: true }
      );

      dispatch({
        type: 'updateCustomerLevelSuccess',
        payload: {
          successMessage: 'Customer level updated succesfully!',
          customer: data.customer,
        },
      });
    } catch (error) {
      dispatch({
        type: 'updateCustomerLevelFailed',
        payload: error.response.data.message,
      });
    }
  };

// delete user level
export const deleteCustomerLevel = (id) => async (dispatch) => {
  try {
    dispatch({
      type: 'deleteCustomerLevelRequest',
    });

    const { data } = await axios.delete(
      `${server}/customer/delete-customer-level/${id}`,
      { withCredentials: true }
    );

    dispatch({
      type: 'deleteCustomerLevelSuccess',
      payload: {
        successMessage: 'Customer deleted successfully!',
        customer: data.customer,
      },
    });
  } catch (error) {
    dispatch({
      type: 'deleteCustomerLevelFailed',
      payload: error.response.data.message,
    });
  }
};

// get All Customers of an eng
export const getSearchCustomersEng = (id, level) => async (dispatch) => {
  try {
    dispatch({
      type: 'getSearchCustomersEngRequest',
    });

    const { data } = await axios.get(
      `${server}/customer/get-all-customer-eng/${id}`,
      {
        level,
      }
    );
    dispatch({
      type: 'getSearchCustomersEngSuccess',
      payload: data.customers,
    });
  } catch (error) {
    dispatch({
      type: 'getSearchCustomersEngFailed',
      payload: error.response.data.message,
    });
  }
};

// delete customer of an eng
export const deleteCustomer = (id) => async (dispatch) => {
  try {
    dispatch({
      type: 'deleteCustomerRequest',
    });

    const { data } = await axios.delete(
      `${server}/customer/delete-eng-customer/${id}`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: 'deleteCustomerSuccess',
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: 'deleteCustomerFailed',
      payload: error.response.data.message,
    });
  }
};
