import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  deleteCustomerLevel,
  getAllCustomersEng,
} from '../../redux/actions/customer';
import { backend_url, server } from '../../server';
import { getAllBrands } from '../../redux/actions/brand';
import Cookies from 'js-cookie';
import { getAllCustomersBranch } from '../../redux/actions/branchCustomer';
import { useTranslation } from 'react-i18next';
import { BsTrash } from 'react-icons/bs';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getCurrentTime } from '../../utils/timeConvertor';

const CustomerDetails = () => {
  const { seller } = useSelector((state) => state.seller);
  const { brands } = useSelector((state) => state.brands);
  const { branchCustomers } = useSelector((state) => state.customerBranch);
  const { customers } = useSelector((state) => state.customers);
  const engBranchId = Cookies.get('branchId');

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(false);
  const { id: customerId } = useParams();
  const [selectBrands, setSelectBrands] = useState([]);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [status, setStatus] = useState('');
  const [allComments, setAllComments] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [reason, setReason] = useState('');
  const [reasonDetail, setReasonDetail] = useState('');
  const [cancelReason, setCancelReason] = useState('');
  const [noties, setNoties] = useState('');
  const [level, setLevel] = useState([]);
  const [image, setImage] = useState();
  const [comment, setComment] = useState('');
  const [fileNo, setFileNo] = useState();

  const cancelList = ['COST', 'TIME', 'OTHERS'];
  useEffect(() => {
    dispatch(getAllCustomersEng(seller._id));
    dispatch(getAllBrands());
    dispatch(getAllCustomersBranch(engBranchId));
  }, [dispatch, seller._id, engBranchId]);

  useEffect(() => {
    if (seller.role === 'BranchAdmin') {
      const isItemExists =
        branchCustomers && branchCustomers.find((i) => i._id === customerId);

      if (isItemExists) {
        branchCustomers &&
          branchCustomers.find((i) =>
            i._id === customerId ? setLevel(i.levels) : ''
          );

        setUpdate(true);
        setName(isItemExists.name);
        setAddress(isItemExists.address);
        setPhone(isItemExists.phone);
        setFileNo(isItemExists?.fileNo);
        setSelectBrands(isItemExists.selectBrands);
        setStatus(isItemExists.levels);
        setAllComments(isItemExists?.comments);
        setReason(isItemExists.reason);
        setTotalPrice(isItemExists.totalPrice);
        setImage(`${backend_url}${isItemExists.image}`);
        setReasonDetail(isItemExists.reasonDetail);
        // setReasonDetail(
        //   level.map((item) =>
        //     item.status === 'Canceled' ? item.reasonDetail : null
        //   )
        // );
        setNoties(isItemExists.noties);
      }
    } else {
      const isItemExists =
        customers && customers.find((i) => i._id === customerId);

      if (isItemExists) {
        customers &&
          customers.find((i) =>
            i._id === customerId ? setLevel(i.levels) : ''
          );

        setUpdate(true);
        setName(isItemExists.name);
        setAddress(isItemExists.address);
        setFileNo(isItemExists?.fileNo);
        setPhone(isItemExists.phone);
        setSelectBrands(isItemExists.selectBrands);
        setStatus(isItemExists.levels);
        setAllComments(isItemExists?.comments);
        setReason(isItemExists.reason);
        setTotalPrice(isItemExists.totalPrice);
        setImage(`${backend_url}${isItemExists.image}`);
        setReasonDetail(isItemExists.reasonDetail);
        // setReasonDetail(
        //   level.map((item) =>
        //     item.status === 'Canceled' ? item.reasonDetail : null
        //   )
        // );
        setNoties(isItemExists.noties);
      }
    }
  }, [customers, branchCustomers, seller.role, customerId]);

  // const handleDelete = (id) => {
  //   // setItems(items.filter(item => item.id !== id));
  //   window.location.reload();
  // };

  // const handleCancel = (event) => {
  //   const value = event.target.value;
  //   const isCancled = event.target.checked;

  //   if (isCancled) {
  //     setCancelReason(value);
  //     setReason(value);
  //     setReasonDetail('');
  //   }
  // };

  const handleSubmitComments = async (e) => {
    e.preventDefault();
    const createdAt = getCurrentTime();
    await axios
      .put(
        `${server}/customer/update-customer-comments/${customerId}`,
        {
          comment,
          name: seller.name,
          createdAt,
        },

        {
          // headers: {
          //   'Content-Type': 'application/json',
          // },
          withCredentials: true,
        }
      )
      .then((res) => {
        toast.success('Comment added succesfully!');
        // dispatch(getAllCustomersEng(seller._id));
        dispatch(getAllCustomersBranch(engBranchId));
        setComment('');
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleDelete = async (id) => {
    await axios
      .delete(
        `${server}/customer/delete-customer-level/${id}`,
        { customerId: customerId },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success(res.data.message);
      });

    // dispatch(getAllBrands());
  };

  // const handleDelete = (item) => {
  //   const id = item._id;
  //   // await axios.delete(`${server}/customer/delete-customer-level/${id}`, {
  //   //   withCredentials: true,
  //   // });
  //   // const id = item._id;
  //   dispatch(deleteCustomerLevel(id));
  // };

  const handleCheck = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      //Add checked item into checkList
      setSelectBrands([...selectBrands, value]);
    } else {
      //Remove unchecked item from checkList
      const filteredList = selectBrands.filter((item) => item.name !== value);
      setSelectBrands(filteredList);
    }
  };

  // Generate string of checked items
  const checkedItems = selectBrands.map((item1) => item1.name).length
    ? selectBrands
        .map((item1) => item1.name)
        .reduce((total, item) => {
          return total + ', ' + item;
        })
    : '';

  var isChecked = (item) =>
    selectBrands.map((item1) => item1.name).includes(item.name)
      ? 'checked-item'
      : 'not-checked-item';

  return (
    <div className="container">
      <div className="flex justify-between mr-3 items-center border-b p-2 border-gray-200">
        <p className="px-3 md:px-2 text-[16px] font-[600] mt-[70px]">
          {t('customer.customer_details')}
        </p>
        <p className="px-3 text-[16px] font-[500] mt-[70px]">
          {/* <Link
            to="/dashboard-register-customer"
            className="w-full flex items-center"
          >
            <span className="text-black flex items-center">
              <AiOutlinePlus size={20} className="mr-2 items-center" />
              Create Product
            </span>
          </Link> */}
        </p>
      </div>

      <div className="container ">
        {/* create product form */}
        <form className="">
          <div className=" grid p-3 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pt-2 gap-4">
            <div className="-mx-3 mb-6 px-4 w-full">
              <div className="flex flex-wrap justify-center items-center -mx-3 mb-6">
                <div className="w-full  px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                    {t('customer.name')}
                  </label>
                  <input
                    className="appearance-none  block w-full bg-[#F1F2F7] text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-[#404041]"
                    id="name"
                    disabled
                    type="text"
                    autoComplete="off"
                    // required
                    // placeholder="Customer Name"
                    name="name"
                    value={name || ''}
                    // onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full  px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                    {t('customer.phone')}
                  </label>
                  <input
                    className="appearance-none block w-full bg-[#F1F2F7] text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-[#404041]"
                    id="phone"
                    type="text"
                    autoComplete="off"
                    disabled
                    required
                    placeholder="Customer Phone"
                    name="phone"
                    value={phone || ''}
                    // onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full  px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                    {t('customer.address')}
                  </label>
                  <input
                    className="appearance-none block w-full bg-[#F1F2F7] text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-[#404041]"
                    id="address"
                    type="text"
                    autoComplete="off"
                    disabled
                    required
                    placeholder="Customer address"
                    name="address"
                    value={address || ''}
                    // onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full  px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                    {t('customer.fileNo')}
                  </label>
                  <input
                    className="appearance-none block w-full bg-[#F1F2F7] text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-[#404041]"
                    id="fileNo"
                    type="text"
                    autoComplete="off"
                    disabled
                    required
                    placeholder="Customer file Number"
                    name="fileNo"
                    value={fileNo || ''}
                    // onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>

              <div className=" px-3 -mx-3">
                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                  {t('customer.brand')}
                </label>
                {brands?.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center w-full  justify-center px-3 py-1 mb-6 md:mb-3 lg:mb-4 xl:mb-4 border"
                  >
                    <input
                      id={index}
                      checked={
                        selectBrands &&
                        selectBrands
                          .map((item1) => item1.name)
                          .includes(item.name)
                          ? true
                          : false
                        // selectBrands && selectBrands.includes(item.name)
                        //   ? true
                        //   : false
                      }
                      type="checkbox"
                      disabled
                      value={item.name}
                      onChange={handleCheck}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      className={`${isChecked(
                        item.name
                      )} w-full py-3 px-2 text-sm font-medium text-gray-900 dark:text-gray-300`}
                    >
                      {item.name}
                    </label>
                    <div className="flex  items-center w-full  justify-center px-3 ">
                      <label className="block px-4 uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                        {t('admin.amount')}
                      </label>
                      <input
                        className="appearance-none block w-full bg-[#F1F2F7] text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-[#404041]"
                        id={item.name}
                        type="number"
                        // required
                        disabled
                        name="amount"
                        value={selectBrands
                          .filter((item1) => item1.name === item.name)
                          .map((itemAmount) => itemAmount.amount)}
                      />
                    </div>
                  </div>
                ))}

                <div>
                  {t('customer.Items_selected_are')}: {`${checkedItems}`}
                </div>
              </div>
              <div className="w-full mt-6 flex items-center justify-start pb-3 flex-wrap">
                {/* <label
                  htmlFor="upload"
                  className="mt-3 mb-3 p-3 items-center justify-center"
                >
                  <div className="w-full flex items-center mt-3 p-2 cursor-pointer bg-[#539165] rounded ">
                    <div className="inline-flex justify-center items-center">
                      <AiOutlinePlusCircle
                        size={30}
                        color="#F1F2F7"
                        className="items-center pr-1"
                      />
                      <h5
                        className={`ml-2  tracking-wide truncate text-[16px] font-[400] text-[#fff]`}
                      >
                        Add Image
                      </h5>
                    </div>
                  </div>
                  
                </label> */}
                {/* <label htmlFor="uploadU" className="mt-3 mb-3 p-3">
                <div className="w-full flex items-center mt-3 p-2 cursor-pointer bg-[#0F6292] rounded ">
                    <div className="inline-flex justify-center items-center">
                      <AiOutlineSnippets
                        size={30}
                        color="#F1F2F7"
                        className="items-center pr-1"
                      />
                      <h5
                        className={`ml-2  tracking-wide truncate text-[16px] font-[400] text-[#fff]`}
                      >
                        Update Image
                      </h5>
                    </div>
                  </div>
                </label> */}
                {image && (
                  <img
                    src={update ? image : URL.createObjectURL(image)}
                    alt=""
                    className="h-full w-full object-cover"
                  />
                )}
              </div>
            </div>

            {/* second div */}
            <div className="flex-wrap -mx-3 mb-2">
              <div className=" w-full  px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold">
                  {t('customer.status')}
                </label>
                <div className=" px-2 -mx-3">
                  {level &&
                    level.map((item, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between w-full py-1 px-3 mb-3 md:mb-0"
                      >
                        <div className="flex  w-full  py-1">
                          <input
                            id={index}
                            type="text"
                            disabled
                            value={item.status}
                            className="  p-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                          />
                        </div>
                        {/* <div className=" px-2 flex justify-start">
                         
                          {item.selected === true ? (
                            <button
                              type="button"
                              onClick={() => handleDelete(item._id)}
                              className="cursor-pointer text-[#F4AA8F] hover:text-[#fff]  appearance-none text-center block"
                            >
                              <BsTrash size={20} fill="#C51605" />
                            </button>
                          ) : 
                         
                          null}
                         
                        </div> */}
                        {/* <div className="flex  justify-center items-center  py-2"></div> */}
                        <div className="flex w-full    justify-center items-center">
                          <p>{item.updatedAt}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              {level &&
              level.find((item, index) => item.status === 'Canceled') ? (
                // level &&
                // level.find((item, index) => item.status === 'Canceled') ? (
                <div>
                  <div className="w-full px-3 py-2 mt-3 mb-4 md:mb-0 ">
                    {cancelList.map((item, index) => (
                      <div
                        key={index}
                        className="flex items-center rounded w-full md:w-2/3 justify-center px-3 py-1 mb-3 md:mb-2 lg:mb-2 xl:mb-2 border"
                      >
                        <input
                          id="bordered-radio-1"
                          checked={reason && reason === item ? true : false}
                          type="radio"
                          value={item}
                          disabled
                          name="bordered-radio"
                          // eslint-disable-next-line no-sequences
                          // onChange={handleCancel}
                          className="w-4 h-4 text-blue-600  bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        ></input>

                        <label
                          className={`${isChecked(
                            item
                          )} w-full py-2 px-2 text-sm font-medium text-gray-900 dark:text-gray-300`}
                        >
                          {item}
                        </label>
                      </div>
                    ))}
                  </div>

                  <div className="w-full  px-3 mb-6 md:mb-0 ">
                    <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                      {t('customer.cancel_reason')}
                    </label>
                    <textarea
                      rows="3"
                      name="reasonDetail"
                      disabled
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Write cancel reason here..."
                      value={reasonDetail || ''}
                      // onChange={(e) => setReasonDetail(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              ) : null}
              <div className="w-full px-3  mt-4 mb-6 md:mb-0 ">
                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                  {t('customer.total')}
                </label>
                <input
                  id="totalPrice"
                  name="totalPrice"
                  disabled
                  value={totalPrice || ''}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Total Amount"
                  onChange={(e) => setTotalPrice(e.target.value)}
                ></input>
              </div>
              <div className="w-full  px-3  mt-4 mb-6 md:mb-0 ">
                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                  {t('customer.note')}
                </label>
                <textarea
                  rows="5"
                  name="noties"
                  disabled
                  value={noties || ''}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Write your noties here..."
                  // onChange={(e) => setNoties(e.target.value)}
                ></textarea>
              </div>
              <div>
                <br />

                {/* <div className="w-full md:w-2/3 px-3 mb-6 md:mb-0 ">
                  <input
                    type="submit"
                    value={update ? 'Update' : 'Create'}
                    className="mt-2 cursor-pointer text-[#F4AA8F] hover:text-[#fff] appearance-none bg-[#404041] hover:bg-[#222222] text-center block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-200 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div> */}
              </div>
            </div>

            {/* Third div */}
            <div className=" mb-6 px-4 w-full ">
              {seller.role === 'BranchAdmin' ? (
                <>
                  <div className="flex-wrap mb-2">
                    <label className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2">
                      {t('customer.comment')}
                    </label>
                    <textarea
                      rows="5"
                      name="comments"
                      value={comment || ''}
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Write comment here..."
                      onChange={(e) => setComment(e.target.value)}
                    ></textarea>
                    <div className="flex items-center justify-end w-full px-3 ">
                      <div className="flex items-center justify-end w-1/3 py-2">
                        <button
                          // type="submit"
                          // value="Submit"
                          className="mt-2 cursor-pointer text-[#F4AA8F] hover:text-[#fff] appearance-none bg-[#404041] hover:bg-[#222222] text-center block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-200 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          onClick={handleSubmitComments}
                        >
                          submit
                        </button>
                      </div>
                    </div>
                  </div>

                  <p className="px-3 md:px-2 text-[18px] font-[600] ">
                    {t('customer.all_comment')}
                  </p>
                  <div className=" w-full border-t border-gray-200">
                    <ul className=" w-full  mt-2 space-y-1 text-[#F4AA8F] list-disc list-inside dark:text-gray-400">
                      {allComments &&
                        allComments?.map((i, index) => (
                          <div
                            key={index}
                            className="py-2 w-full  px-2 border border-gray-200 rounded-lg "
                          >
                            <div className="flex items-center   text-blue-600">
                              <svg
                                className="w-3.5 h-3.5 me-2 text-[#539165] dark:text-green-400 flex-shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              <div className="flex items-center justify-between text-blue-600">
                                <div className=" text-blue-600">{i.name}</div>
                                <div className="px-3 text-blue-600">
                                  {'('} {i.createdAt}
                                  {' )'}
                                </div>
                              </div>
                            </div>
                            <ol className="ps-5 text-[#222222] mt-2 space-y-1 list-decimal list-inside">
                              {i.comment}
                            </ol>
                          </div>
                        ))}
                    </ul>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomerDetails;
