import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
};

export const customerReducer = createReducer(initialState, {
  customerCreateRequest: (state) => {
    state.isLoading = true;
  },
  customerCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.customer = action.payload;
    state.success = true;
  },
  customerCreateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  // get all cutomers of eng
  getAllCustomersEngRequest: (state) => {
    state.isLoading = true;
  },
  getAllCustomersEngSuccess: (state, action) => {
    state.isLoading = false;
    state.customers = action.payload;
  },
  getAllCustomersEngFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  //delete cutomer of an eng
  deleteCustomerRequest: (state) => {
    state.isLoading = true;
  },
  deleteCustomerSuccess: (state, action) => {
    state.isLoading = false;
    state.message = action.payload;
  },
  deleteCustomerFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  //get Search customers
  getSearchCustomersEngRequest: (state) => {
    state.isLoading = true;
  },
  getSearchCustomersEngSuccess: (state, action) => {
    state.isLoading = false;
    state.customers = action.payload;
  },
  getSearchCustomersEngFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // update Customer Level
  updateCustomerLevelRequest: (state) => {
    state.levelloading = true;
  },
  updateCustomerLevelSuccess: (state, action) => {
    state.levelloading = false;
    state.successMessage = action.payload.successMessage;
    state.customer = action.payload.customer;
  },
  updateCustomerLevelFailed: (state, action) => {
    state.levelloading = false;
    state.error = action.payload;
  },

  // delete Customer Level
  deleteCustomerLevelRequest: (state) => {
    state.levelloading = true;
  },
  deleteCustomerLevelSuccess: (state, action) => {
    state.levelloading = false;
    state.successMessage = action.payload.successMessage;
    state.customer = action.payload.customer;
  },
  deleteCustomerLevelFailed: (state, action) => {
    state.levelloading = false;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
