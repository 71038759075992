import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
};

export const customerBranchReducer = createReducer(initialState, {
  // get all cutomers of eng
  getAllCustomersBranchRequest: (state) => {
    state.isLoading = true;
  },
  getAllCustomersBranchSuccess: (state, action) => {
    state.isLoading = false;
    state.branchCustomers = action.payload;
  },
  getAllCustomersBranchFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
