import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { server } from '../../server';
import styles from '../../styles/styles';
import { RxAvatar } from 'react-icons/rx';
import axios from 'axios';
import UpdateCustomers from '../CustomerNotification/UpdateCustomers';
import { useTranslation } from 'react-i18next';

const headerLogo = '../../../../images/logo.png';

const AdminHeader = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { notification } = useSelector((state) => state.notification);
  const [openNote, setOpenNote] = useState(false);
  const [isList, setIsList] = useState(false);

  const logoutHandler = async () => {
    axios.get(`${server}/user/logout`, {
      withCredentials: true,
    });
    window.location.reload();
  };

  return (
    <div
      className="fixed w-full h-[60px] bg-[#F1F2F7] shadow-lg
  flex items-center justify-between px-6  z-10"
    >
      <div>
        {user && user.role === 'StorageAdmin' ? (
          <Link to="/storage">
            <div className="flex items-center justify-center py-4 ">
              <div className="flex justify-start items-center">
                <img
                  src={headerLogo}
                  alt="logo"
                  className=" object-cover w-[50px]  h-[50px] rounded-full border-2  cursor-pointer"
                />
              </div>
              <div
                className={`${styles.brandTitle} flex justify-start items-center px-1`}
              >
                <p className=" text-[14px] lg:text-[20px] xl:text-[20px] md:text-[18px] font-[500]">
                  {t('main.main_title')}
                </p>
              </div>
            </div>
          </Link>
        ) : (
          <Link to="/admin/dashboard">
            <div className="flex items-center justify-center py-4 ">
              <div className="flex justify-start items-center">
                <img
                  src={headerLogo}
                  alt="logo"
                  className=" object-cover w-[50px]  h-[50px] rounded-full border-2  cursor-pointer"
                />
              </div>
              <div
                className={`${styles.brandTitle} flex justify-start items-center px-1`}
              >
                <p className=" text-[14px] lg:text-[20px] xl:text-[20px] md:text-[18px] font-[500]">
                  {t('main.main_title')}
                </p>
              </div>
            </div>
          </Link>
        )}
      </div>

      <div className="flex items-center justify-center ">
        {user && user.role !== 'StorageAdmin' ? (
          <div className={`${styles.noramlFlex}`}>
            <div
              className="relative cursor-pointer mr-[15px]"
              onClick={() => setOpenNote(true)}
            >
              <RxAvatar color="#F4AA8F" size={25} className=" cursor-pointer" />
              <span className="absolute right-0 rtl:left-0 top-4 rounded-full bg-[#C51605] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
                {notification && notification.length}
              </span>
            </div>
          </div>
        ) : null}
        <div className="flex items-center mr-2 lg:mr-3 xl:mr-3 md:mr-3">
          {/* <Link to={`/engineer/${user._id}`}>
            <RxAvatar color="#F4AA8F" size={25} className=" cursor-pointer" />
          </Link> */}

          {/* -------------- */}
          <div
            onClick={() => setIsList(!isList)}
            className="flex justify-between items-center cursor-pointer"
          >
            <p className="text-[#404041] px-1 text-[11px] lg:text-[14px] xl:text-[14px] md:text-[12px] font-[500] ">
              {user.name}
            </p>
            <svg
              className="-mr-1 h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                // fill-rule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                // clip-rule="evenodd"
              />
            </svg>
          </div>
          {isList && (
            <div className="absolute ltr:-right-3 rtl:left-0 w-32 mt-[75px] bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600">
              {/* <div className="">
                <Link
                  to={`/engineer/${user._id}`}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                >
                  <p className="text-[#404041] px-1 text-[11px] lg:text-[14px] xl:text-[14px] md:text-[12px] font-[500]">
                    Profile
                  </p>
                </Link>
              </div> */}
              <div
                onClick={() => logoutHandler()}
                className="block px-4 py-2 cursor-pointer text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
              >
                <p className="text-[#404041] px-1 text-[11px] lg:text-[14px] xl:text-[14px] md:text-[12px] font-[500]">
                  {t('main.logout')}
                </p>
              </div>
            </div>
          )}

          {/* -------------------- */}
        </div>
      </div>
      {openNote ? <UpdateCustomers setOpenNote={setOpenNote} /> : null}
    </div>
  );
};

export default AdminHeader;
