import { configureStore } from '@reduxjs/toolkit';
import { userReducer } from './reducers/user';
import { sellerReducer } from './reducers/seller';
import { customerReducer } from './reducers/customer';
import { adminReducer } from './reducers/admin';
import { productReducer } from './reducers/product';
import { customerBranchReducer } from './reducers/branchCustomer';
import { brandReducer } from './reducers/brand';
import { branchReducer } from './reducers/branch';
import { notificationReducer } from './reducers/notification';
import { storageReducer } from './reducers/storage';

const Store = configureStore({
  reducer: {
    user: userReducer,
    seller: sellerReducer,
    customers: customerReducer,
    admin: adminReducer,
    products: productReducer,
    customerBranch: customerBranchReducer,
    brands: brandReducer,
    branches: branchReducer,
    storages: storageReducer,
    notification: notificationReducer,
  },
});

export default Store;
