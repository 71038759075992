import React from 'react';
import { FiPackage, FiUsers } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { BiCategoryAlt, BiChip, BiGitBranch, BiMoney } from 'react-icons/bi';
// import {
//   AiOutlineAntDesign,
//   AiOutlineAppstoreAdd,
//   AiOutlineBuild,
//   AiOutlineStop,
// } from 'react-icons/ai';
import { FaUsers } from 'react-icons/fa';
import { BsCheck2Circle, BsXCircle } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AiOutlineAppstoreAdd } from 'react-icons/ai';

const AdminSideBar = ({ active }) => {
  const { user } = useSelector((state) => state.user);
  const { t } = useTranslation();
  return (
    // <div class="fixed flex flex-1 rtl:right-0 overflow-y-auto overflow-x-hidden mt-[60px] left-0 w-14 hover:w-60 lg:w-60 p-3 bg-[#404041] h-full text-white transition-transform duration-200 border-none z-10 sidebar">
    <div className="fixed flex flex-1 overflow-y-auto overflow-hidden min-h-0 rtl:right-0  flex-col mt-[60px] left-0 w-14 hover:w-60 lg:w-60 p-3 bg-[#404041]  h-full text-white transition-transform duration-200 border-none z-40 sidebar">
      <div className=" overflow-x-hidden snap-start">
        {user && user.role === 'StorageAdmin' ? (
          <>
          <Link to="/storage">
            <div
              className={`w-full flex items-center mt-1 p-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                active === 1 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
              }`}
            >
              <div className="inline-flex justify-center items-center">
                <AiOutlineAppstoreAdd
                  size={22}
                  color={`${active === 1 ? '#fff' : '#F4AA8F'}`}
                  className="items-center pr-1"
                />
                <h5
                  className={`px-2  tracking-wide truncate text-[16px] font-[400]  ${
                    active === 1 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                  }`}
                >
                  {t('main.store')}
                </h5>
              </div>
            </div>
          </Link>
          <Link to="/storageList">
            <div
              className={`w-full flex items-center mt-1 p-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                active === 2 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
              }`}
            >
              <div className="inline-flex justify-center items-center">
                <AiOutlineAppstoreAdd
                  size={22}
                  color={`${active === 2 ? '#fff' : '#F4AA8F'}`}
                  className="items-center pr-1"
                />
                <h5
                  className={`px-2  tracking-wide truncate text-[16px] font-[400]  ${
                    active === 2 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                  }`}
                >
                  {t('main.delivered')}
                </h5>
              </div>
            </div>
          </Link>
          </>
          
        ) : (
          <>
            {user && user.role === 'MiniAdmin' ? (
              <>
                <Link to="/admin/dashboard">
                  <div
                    className={`w-full flex items-center mt-1 p-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                      active === 1 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                    }`}
                  >
                    <div className="inline-flex justify-center items-center">
                      <BsXCircle
                        size={22}
                        color={`${active === 1 ? '#fff' : '#F4AA8F'}`}
                        className="items-center pr-1"
                      />
                      <h5
                        className={`px-2  tracking-wide truncate text-[16px] font-[400]  ${
                          active === 1 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                        }`}
                      >
                        {t('main.dashboard')}
                      </h5>
                    </div>
                  </div>
                </Link>
                <Link to="/admin/summary">
                  <div
                    className={`w-full flex items-center mt-1 px-1 py-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                      active === 2 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                    }`}
                  >
                    <div className="inline-flex  justify-center items-center">
                      <FiPackage
                        size={22}
                        color={`${active === 2 ? '#fff' : '#F4AA8F'}`}
                        className="items-center pr-1 "
                      />
                      <h5
                        className={`px-2 tracking-wide truncate text-[16px] font-[400]  ${
                          active === 2 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                        }`}
                      >
                        {t('main.branch_summary')}
                      </h5>
                    </div>
                  </div>
                </Link>
                <Link to="/admin/storageorders">
                  <div
                    className={`w-full flex items-center mt-1 px-1 py-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                      active === 18 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                    }`}
                  >
                    <div className="inline-flex justify-center items-center">
                      <AiOutlineAppstoreAdd
                        size={22}
                        color={`${active === 18 ? '#fff' : '#F4AA8F'}`}
                        className="items-center pr-1"
                      />
                      <h5
                        className={`px-2 tracking-wide truncate text-[16px] font-[400]  ${
                          active === 18 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                        }`}
                      >
                        {t('main.store')}
                      </h5>
                    </div>
                  </div>
                </Link>
              </>
            ) : (
              <>
                <Link to="/admin/dashboard">
                  <div
                    className={`w-full flex items-center mt-1 px-1 py-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                      active === 1 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                    }`}
                  >
                    <div className="inline-flex  justify-center items-center">
                      <FiPackage
                        size={22}
                        color={`${active === 1 ? '#fff' : '#F4AA8F'}`}
                        className="items-center pr-1 "
                      />
                      <h5
                        className={`px-2 tracking-wide truncate text-[16px] font-[400]  ${
                          active === 1 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                        }`}
                      >
                        {t('main.dashboard')}
                      </h5>
                    </div>
                  </div>
                </Link>

                <Link to="/admin/summary">
                  <div
                    className={`w-full flex items-center mt-1 px-1 py-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                      active === 2 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                    }`}
                  >
                    <div className="inline-flex  justify-center items-center">
                      <FiPackage
                        size={22}
                        color={`${active === 2 ? '#fff' : '#F4AA8F'}`}
                        className="items-center pr-1 "
                      />
                      <h5
                        className={`px-2 tracking-wide truncate text-[16px] font-[400]  ${
                          active === 2 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                        }`}
                      >
                        {t('main.branch_summary')}
                      </h5>
                    </div>
                  </div>
                </Link>

                <Link to="/admin/branches">
                  <div
                    className={`w-full flex items-center mt-1 px-1 py-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                      active === 3 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                    }`}
                  >
                    <div className="inline-flex justify-center items-center">
                      <BiGitBranch
                        size={22}
                        color={`${active === 3 ? '#fff' : '#F4AA8F'}`}
                        className="items-center pr-1"
                      />
                      <h5
                        className={`px-2  tracking-wide truncate text-[16px] font-[400]  ${
                          active === 3 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                        }`}
                      >
                        {t('admin.all_branchs')}
                      </h5>
                    </div>
                  </div>
                </Link>

                <Link to="/admin/Engineers">
                  <div
                    className={`w-full flex items-center mt-1 px-1 py-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                      active === 4 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                    }`}
                  >
                    <div className="inline-flex justify-center items-center">
                      <BiChip
                        size={22}
                        color={`${active === 4 ? '#fff' : '#F4AA8F'}`}
                        className="items-center pr-1"
                      />
                      <h5
                        className={`px-2 tracking-wide truncate text-[16px] font-[400]  ${
                          active === 4 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                        }`}
                      >
                        {t('admin.all_engineer')}
                      </h5>
                    </div>
                  </div>
                </Link>

                <Link to="/admin/users">
                  <div
                    className={`w-full flex items-center mt-1 px-1 py-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                      active === 5 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                    }`}
                  >
                    <div className="inline-flex justify-center items-center">
                      <FiUsers
                        size={22}
                        color={`${active === 5 ? '#fff' : '#F4AA8F'}`}
                        className="items-center pr-1"
                      />
                      <h5
                        className={`px-2 tracking-wide truncate text-[16px] font-[400]  ${
                          active === 5 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                        }`}
                      >
                        {t('admin.all_users')}
                      </h5>
                    </div>
                  </div>
                </Link>

                <Link to="/admin/brands">
                  <div
                    className={`w-full flex items-center mt-1 px-1 py-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                      active === 6 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                    }`}
                  >
                    <div className="inline-flex justify-center items-center">
                      <BiCategoryAlt
                        size={22}
                        color={`${active === 6 ? '#fff' : '#F4AA8F'}`}
                        className="items-center pr-1"
                      />
                      <h5
                        className={`px-2 tracking-wide truncate text-[16px] font-[400]  ${
                          active === 6 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                        }`}
                      >
                        {t('admin.all_brands')}
                      </h5>
                    </div>
                  </div>
                </Link>

                <Link
                  to="/admin/customers"
                  state={{ All: 'All', sellerId: '', branchId: '' }}
                >
                  <div
                    className={`w-full flex items-center mt-1 px-1 py-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                      active === 7 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                    }`}
                  >
                    <div className="inline-flex justify-center items-center">
                      <FaUsers
                        size={22}
                        color={`${active === 7 ? '#fff' : '#F4AA8F'}`}
                        className="items-center pr-1"
                      />
                      <h5
                        className={`px-2  tracking-wide truncate text-[16px] font-[400]  ${
                          active === 7 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                        }`}
                      >
                        {t('main.customers')}
                      </h5>
                    </div>
                  </div>
                </Link>

                {/* <Link to="/admin/measurements">
              <div
                className={`w-full flex items-center mt-1 px-1 py-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                  active === 7 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                }`}
              >
                <div className="inline-flex justify-center items-center">
                  <BiRuler
                    size={22}
                    color={`${active === 7 ? '#fff' : '#F4AA8F'}`}
                    className="items-center pr-1"
                  />
                  <h5
                    className={`px-2 tracking-wide truncate text-[16px] font-[400]  ${
                      active === 7 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                    }`}
                  >
                    {t('main.measurements')}
                  </h5>
                </div>
              </div>
            </Link>
            <Link to="/admin/designs">
              <div
                className={`w-full flex items-center mt-1 px-1 py-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                  active === 8 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                }`}
              >
                <div className="inline-flex justify-center items-center">
                  <AiOutlineAntDesign
                    size={22}
                    color={`${active === 8 ? '#fff' : '#F4AA8F'}`}
                    className="items-center pr-1"
                  />
                  <h5
                    className={`px-2 tracking-wide truncate text-[16px] font-[400]  ${
                      active === 8 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                    }`}
                  >
                    {t('main.design')}
                  </h5>
                </div>
              </div>
            </Link>
            <Link to="/admin/discussions">
              <div
                className={`w-full flex items-center mt-1 px-1 py-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                  active === 9 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                }`}
              >
                <div className="inline-flex justify-center items-center">
                  <BsChatRightText
                    size={22}
                    color={`${active === 9 ? '#fff' : '#F4AA8F'}`}
                    className="items-center pr-1"
                  />
                  <h5
                    className={`px-2 tracking-wide truncate text-[16px] font-[400]  ${
                      active === 9 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                    }`}
                  >
                    {t('main.discussion')}
                  </h5>
                </div>
              </div>
            </Link>
            <Link to="/admin/holdings">
              <div
                className={`w-full flex items-center mt-1 px-1 py-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                  active === 10 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                }`}
              >
                <div className="inline-flex justify-center items-center">
                  <FaRegHandPaper
                    size={22}
                    color={`${active === 10 ? '#fff' : '#F4AA8F'}`}
                    className="items-center pr-1"
                  />
                  <h5
                    className={`px-2 tracking-wide truncate text-[16px] font-[400]  ${
                      active === 10 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                    }`}
                  >
                    {t('main.holding')}
                  </h5>
                </div>
              </div>
            </Link>
            <Link to="/admin/contracts">
              <div
                className={`w-full flex items-center mt-1 px-1 py-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                  active === 11 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                }`}
              >
                <div className="inline-flex justify-center items-center">
                  <FaRegNewspaper
                    size={22}
                    color={`${active === 11 ? '#fff' : '#F4AA8F'}`}
                    className="items-center pr-1"
                  />
                  <h5
                    className={`px-2 tracking-wide truncate text-[16px] font-[400]  ${
                      active === 11 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                    }`}
                  >
                    {t('main.contract')}
                  </h5>
                </div>
              </div>
            </Link>
            <Link to="/admin/stop-orders">
              <div
                className={`w-full flex items-center mt-1 p-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                  active === 12 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                }`}
              >
                <div className="inline-flex justify-center items-center">
                  <AiOutlineStop
                    size={22}
                    color={`${active === 12 ? '#fff' : '#F4AA8F'}`}
                    className="items-center pr-1"
                  />
                  <h5
                    className={`px-2  tracking-wide truncate text-[16px] font-[400]  ${
                      active === 12 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                    }`}
                  >
                    {t('main.stop_order')}
                  </h5>
                </div>
              </div>
            </Link>

            <Link to="/admin/store">
              <div
                className={`w-full flex items-center mt-1 p-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                  active === 13 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                }`}
              >
                <div className="inline-flex justify-center items-center">
                  <AiOutlineAppstoreAdd
                    size={22}
                    color={`${active === 13 ? '#fff' : '#F4AA8F'}`}
                    className="items-center pr-1"
                  />
                  <h5
                    className={`px-2  tracking-wide truncate text-[16px] font-[400]  ${
                      active === 13 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                    }`}
                  >
                    {t('main.store')}
                  </h5>
                </div>
              </div>
            </Link>
            <Link to="/admin/implementations">
              <div
                className={`w-full flex items-center mt-1 px-1 py-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                  active === 14 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                }`}
              >
                <div className="inline-flex justify-center items-center">
                  <AiOutlineBuild
                    size={22}
                    color={`${active === 14 ? '#fff' : '#F4AA8F'}`}
                    className="items-center pr-1"
                  />
                  <h5
                    className={`px-2 tracking-wide truncate text-[16px] font-[400]  ${
                      active === 14 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                    }`}
                  >
                    {t('main.implementations')}
                  </h5>
                </div>
              </div>
            </Link> */}
                <Link
                  to="/admin/completes"
                  state={{ All: 'All', sellerId: '', branchId: '' }}
                >
                  <div
                    className={`w-full flex items-center mt-1 px-1 py-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                      active === 15 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                    }`}
                  >
                    <div className="inline-flex justify-center items-center">
                      <BsCheck2Circle
                        size={22}
                        color={`${active === 15 ? '#fff' : '#F4AA8F'}`}
                        className="items-center pr-1"
                      />
                      <h5
                        className={`px-2  tracking-wide truncate text-[16px] font-[400]  ${
                          active === 15 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                        }`}
                      >
                        {t('main.completed')}
                      </h5>
                    </div>
                  </div>
                </Link>
                <Link
                  to="/admin/canceled"
                  state={{ All: 'All', sellerId: '', branchId: '' }}
                >
                  <div
                    className={`w-full flex items-center mt-1 px-1 py-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                      active === 16 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                    }`}
                  >
                    <div className="inline-flex justify-center items-center">
                      <BsXCircle
                        size={22}
                        color={`${active === 16 ? '#fff' : '#F4AA8F'}`}
                        className="items-center pr-1"
                      />
                      <h5
                        className={`px-2  tracking-wide truncate text-[16px] font-[400]  ${
                          active === 16 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                        }`}
                      >
                        {t('main.canceled')}
                      </h5>
                    </div>
                  </div>
                </Link>
                <Link to="/admin/contractAmounts">
                  <div
                    className={`w-full flex items-center mt-1 px-1  py-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                      active === 17 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                    }`}
                  >
                    <div className="inline-flex justify-center items-center">
                      <BiMoney
                        size={22}
                        color={`${active === 17 ? '#fff' : '#F4AA8F'}`}
                        className="items-center pr-1"
                      />
                      <h5
                        className={`px-2 tracking-wide truncate text-[16px] font-[400]  ${
                          active === 17 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                        }`}
                      >
                        {t('admin.contracts_amounts')}
                      </h5>
                    </div>
                  </div>
                </Link>
                <Link to="/admin/storageorders">
                  <div
                    className={`w-full flex items-center mt-1 px-1 mb-16 py-2 cursor-pointer hover:bg-[#222222] hover:rounded ${
                      active === 18 ? 'bg-[#222222] rounded' : 'bg-[#404041]'
                    }`}
                  >
                    <div className="inline-flex justify-center items-center">
                      <AiOutlineAppstoreAdd
                        size={22}
                        color={`${active === 18 ? '#fff' : '#F4AA8F'}`}
                        className="items-center pr-1"
                      />
                      <h5
                        className={`px-2 tracking-wide truncate text-[16px] font-[400]  ${
                          active === 18 ? 'text-[#fff]' : 'text-[#F4AA8F]'
                        }`}
                      >
                        {t('main.mainstorage')}
                      </h5>
                    </div>
                  </div>
                </Link>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AdminSideBar;
