import React from 'react'
import AdminHeader from '../components/Layout/AdminHeader'
import AdminSideBar from '../components/Admin/Layout/AdminSideBar'
import AllDesigns from "../components/Admin/AllDesigns";

const AdminAllDesigns = () => {
  return (
    <div>
      <AdminHeader />
      <div className="flex items-start justify-between w-full">
        <div className="w-[70px] lg:w-[320px] xl:w-[290px] md:w-[60px]">
          <AdminSideBar active={8} />
        </div>
        <AllDesigns />
      </div>
    </div>
  )
}

export default AdminAllDesigns