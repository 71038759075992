import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
};

export const adminReducer = createReducer(initialState, {
  //delete cutomer of an eng
  deleteCustomerRequest: (state) => {
    state.isLoading = true;
  },
  deleteCustomerSuccess: (state, action) => {
    state.isLoading = false;
    state.message = action.payload;
  },
  deleteCustomerFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  //get Search customers
  getSearchCustomersEngRequest: (state) => {
    state.isLoading = true;
  },
  getSearchCustomersEngSuccess: (state, action) => {
    state.isLoading = false;
    state.customers = action.payload;
  },
  getSearchCustomersEngFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // get all custoomers Admin
  getAllCustomersAdminRequest: (state) => {
    state.isLoading = true;
  },
  getAllCustomersAdminSuccess: (state, action) => {
    state.isLoading = false;
    state.customers = action.payload;
  },
  getAllCustomersAdminFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
