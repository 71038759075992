import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { Link } from 'react-router-dom';
import styles from '../../styles/styles';
import cookies from 'js-cookie';

const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
    dir: 'ltr',
  },
  {
    code: 'ar',
    name: 'عربي',
    country_code: 'ar',
    dir: 'rtl',
  },
];

const Header = ({ activeHeading }) => {
  const { t } = useTranslation();
  // const [active, setActive] = useState(false);

  // window.addEventListener('scroll', () => {
  //   if (window.scrollY > 120) {
  //     setActive(true);
  //   } else {
  //     setActive(false);
  //   }
  // });

  const currentLanguageCode = cookies.get('i18next') || 'en';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'rtl';
    document.title = t('main.main_title');
  }, [currentLanguage, t]);

  const headerLogo = '../../../images/logo.png';
  return (
    <div
      className={` w-full border-b-[1px] border-gray-200 top-0 bg-[#404041] `}
    >
      <div className={` ${styles.section} flex justify-between`}>
        <div>
          <Link to="/">
            <div className="flex items-center justify-center py-4 ">
              <div className="flex justify-start items-center">
                <img
                  src={headerLogo}
                  alt="logo"
                  className=" object-cover w-[80px]  h-[80px] rounded-full border-2  cursor-pointer"
                />
              </div>
              <div
                className={`${styles.brandTitle} flex justify-start items-center px-2 lg:px-3 xl:px-4 md:px-3`}
              >
                <p className=" text-[18px] lg:text-[28px] xl:text-[28px] md:text-[24px] font-[500]">
                  {t('main.main_title')}
                </p>
              </div>
            </div>
          </Link>
        </div>

        <div className={`${styles.noramlFlex} `}>
          {/* {languages.map(({ code, name, country_code }) => (
            <NavDropdown.Item
              key={country_code}
              onClick={() => i18next.changeLanguage(code)}
              disabled={code === currentLanguageCode}
            >
             
              {name}
            </NavDropdown.Item>
          ))} */}
          <Link to="/">
            <div className="  flex justify-between ">
              {languages.map(({ code, name, country_code }) => (
                <div
                  key={country_code}
                  onClick={() => i18next.changeLanguage(code)}
                  // disabled={code === currentLanguageCode}
                  // className=""
                >
                  <p className="text-[16px] hover:text-[#F4AA8F] lg:text-[18px] xl:text-[18px] md:text-[16px] font-[300] text-[#fff]  px-2">
                    {name}
                  </p>
                </div>
              ))}
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
