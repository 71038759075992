import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  notification: localStorage.getItem('notificationItems')
    ? JSON.parse(localStorage.getItem('notificationItems'))
    : [],
};

export const notificationReducer = createReducer(initialState, {
  addToNotification: (state, action) => {
    const item = action.payload;
    const isItemExist = state.notification.find((i) => i._id === item._id);
    if (isItemExist) {
      return {
        ...state,
        notification: state.notification.map((i) =>
          i._id === isItemExist._id ? item : i
        ),
      };
    } else {
      return {
        ...state,
        notification: [...state.notification, item],
      };
    }
  },

  removeFromNotification: (state, action) => {
    return {
      ...state,
      notification: state.notification.filter((i) => i._id !== action.payload),
    };
  },
});
