import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { LoginPage, HomePage, EngineeringLoginPage } from './routes/Routes.js';
import {
  ShopDashboardPage,
  BranchAdminEngineers,
  ShopCreateCustomer,
  EngCustomerDetails,
  EngHomePage,
  EngAllCustomers,
  EngAllMeasurements,
  EngAllDesigns,
  EngAllDiscussion,
  EngAllHoldings,
  EngAllContracts,
  EngAllImplementations,
  EngAllCompletes,
  EngAllCanceled,
  EngTransferCustomer,
  EngAllStopOrder,
  EngAllStore,
  EngAllStorageOrders,
} from './routes/EngineeringRoutes';

import {
  AdminDashboardPage,
  AdminSummary,
  AdminDashboardUsers,
  AdminAllBranch,
  AdminCreateUser,
  AdminDashboardEngineering,
  AdminDashboardCustomers,
  AdminCustomerDetails,
  AdminDashboardMeasurements,
  AdminAllDesigns,
  AdminAllDiscussions,
  AdminAllHoldings,
  AdminAllContracts,
  AdminAllStopOrders,
  AdminAllStore,
  AdminAllImplementations,
  AdminAllCompletes,
  AdminAllCanceled,
  AdminAllBrands,
  AdminContractAmounts,
  AdminAllStorageOrders,
  AdminStorageOrders,
  AdminStorageList,
} from './routes/AdminRoutes';
import { StorageAdminPage } from './routes/StorageRoutes';
import { EngCreateCustomerSeller } from './routes/AdminSellerRoute';
import io from 'socket.io-client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Store from './redux/store';
import { loadSeller, loadUser } from './redux/actions/user';
// import { server } from './server';
import ProtectedAdminRoute from './routes/ProtectedAdminRoute';
import SellerProtectedRoute from './routes/SellerProtectedRoute';
import { getAllBranchs } from './redux/actions/branch';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

const socket = io.connect('https://delos-ops.com/api/');
// const socket = io.connect('http://localhost:5000');
socket.on('disconnect', () => {
  toast.error('Socket is disconnected!');
  // setIsConnected(false);
});
socket.on('connection', () => {
  // Toast.show({
  //   topOffset: 10,
  //   type: 'success',
  //   text1: 'Socket is connected',
  //   text2: '',
  // });
  // setIsConnected(false);
});
socket.on('reconnection', (data) => {
  toast.success(`${data.status} Socket is connected!`);
});

const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
    dir: 'ltr',
  },
  {
    code: 'ar',
    name: 'عربي',
    country_code: 'ar',
    dir: 'rtl',
  },
];

const App = () => {
  // const { seller } = useSelector((state) => state.seller);
  useEffect(() => {
    Store.dispatch(loadUser());
    Store.dispatch(loadSeller());
    Store.dispatch(getAllBranchs());
    //Store.dispatch(getAllProducts());
    //Store.dispatch(getAllEvents());
    //getStripeApikey();
  }, []);

  const currentLanguageCode = Cookies.get('i18next') || 'en';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'rtl';
    // document.title = t('main.main_title');
  }, [currentLanguage]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />

        <Route path="/engineer-login/:id" element={<EngineeringLoginPage />} />

        {/* <Route
          path="/createCustomerSeller"
          element={
            <AdminSellerProtectRoute>
              <EngCreateCustomerSeller />
            </AdminSellerProtectRoute>
          }
        /> */}
        <Route
          path="/dashbord/createCustomerSeller"
          element={
            <SellerProtectedRoute>
              <EngCreateCustomerSeller />
            </SellerProtectedRoute>
          }
        />

        <Route
          path="/engineer/:id"
          element={
            <SellerProtectedRoute>
              <EngHomePage />
            </SellerProtectedRoute>
          }
        />

        <Route
          path="/dashboard"
          element={
            <SellerProtectedRoute>
              <ShopDashboardPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard/Engineers"
          element={
            <SellerProtectedRoute>
              <BranchAdminEngineers />
            </SellerProtectedRoute>
          }
        />

        <Route
          path="/dashboard/register/customer"
          element={
            <SellerProtectedRoute>
              <ShopCreateCustomer />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard/customers"
          element={
            <SellerProtectedRoute>
              <EngAllCustomers />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard/register/customer/:id"
          element={
            <SellerProtectedRoute>
              <ShopCreateCustomer />
            </SellerProtectedRoute>
          }
        />

        <Route
          path="/dashboard/customer/details/:id"
          element={
            <SellerProtectedRoute>
              <EngCustomerDetails />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard/transfer/:id"
          element={
            <SellerProtectedRoute>
              <EngTransferCustomer />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard/measurements"
          element={
            <SellerProtectedRoute>
              <EngAllMeasurements />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard/design"
          element={
            <SellerProtectedRoute>
              <EngAllDesigns />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard/discussion"
          element={
            <SellerProtectedRoute>
              <EngAllDiscussion />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard/holdings"
          element={
            <SellerProtectedRoute>
              <EngAllHoldings />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard/stoporder"
          element={
            <SellerProtectedRoute>
              <EngAllStopOrder />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard/store"
          element={
            <SellerProtectedRoute>
              <EngAllStore />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard/contracts"
          element={
            <SellerProtectedRoute>
              <EngAllContracts />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard/implementations"
          element={
            <SellerProtectedRoute>
              <EngAllImplementations />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard/completes"
          element={
            <SellerProtectedRoute>
              <EngAllCompletes />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard/canceled"
          element={
            <SellerProtectedRoute>
              <EngAllCanceled />
            </SellerProtectedRoute>
          }
        />

        <Route
          path="/dashboard/storage"
          element={
            <SellerProtectedRoute>
              <EngAllStorageOrders />
            </SellerProtectedRoute>
          }
        />

        {/* Admin Routes */}
        {/* <Route path="/admin" element={<AdminHome />} /> */}
        {/* <Route
          path="/admin/login"
          element={
            <ProtectedAdminRoute>
              <LoginPage />
            </ProtectedAdminRoute>
          }
        /> */}
        {/* <Route
          path="/admin"
          element={
            <ProtectedAdminRoute>
              <AdminHome />
            </ProtectedAdminRoute>
          }
        /> */}

        {/* <Route
          path="/admin/summary"
          element={
            <ProtectedAdminRoute>
              <AdminSummaryPage />
            </ProtectedAdminRoute>
          }
        /> */}
        <Route
          path="/storage"
          element={
            <ProtectedAdminRoute>
              <StorageAdminPage />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/storageList"
          element={
            <ProtectedAdminRoute>
              <AdminStorageList />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin/dashboard"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardPage />
            </ProtectedAdminRoute>
          }
        />

        <Route
          path="/admin/summary"
          element={
            <ProtectedAdminRoute>
              <AdminSummary />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin/storage"
          element={
            <ProtectedAdminRoute>
              <AdminAllStorageOrders />
            </ProtectedAdminRoute>
          }
        />

        <Route
          path="/admin/storageorders"
          element={
            <ProtectedAdminRoute>
              <AdminStorageOrders />
            </ProtectedAdminRoute>
          }
        />

        <Route
          path="/admin/users"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardUsers />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin/branches"
          element={
            <ProtectedAdminRoute>
              <AdminAllBranch />
            </ProtectedAdminRoute>
          }
        />

        <Route
          path="/admin-Create-user"
          element={
            <ProtectedAdminRoute>
              <AdminCreateUser />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin/Engineers"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardEngineering />
            </ProtectedAdminRoute>
          }
        />

        <Route
          path="/admin/brands"
          element={
            <ProtectedAdminRoute>
              <AdminAllBrands />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin/customers"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardCustomers />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin/customers/:id"
          element={
            <ProtectedAdminRoute>
              <AdminCustomerDetails />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin/measurements"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardMeasurements />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin/designs"
          element={
            <ProtectedAdminRoute>
              <AdminAllDesigns />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin/discussions"
          element={
            <ProtectedAdminRoute>
              <AdminAllDiscussions />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin/holdings"
          element={
            <ProtectedAdminRoute>
              <AdminAllHoldings />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin/contracts"
          element={
            <ProtectedAdminRoute>
              <AdminAllContracts />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin/stop-orders"
          element={
            <ProtectedAdminRoute>
              <AdminAllStopOrders />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin/store"
          element={
            <ProtectedAdminRoute>
              <AdminAllStore />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin/implementations"
          element={
            <ProtectedAdminRoute>
              <AdminAllImplementations />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin/completes"
          element={
            <ProtectedAdminRoute>
              <AdminAllCompletes />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin/canceled"
          element={
            <ProtectedAdminRoute>
              <AdminAllCanceled />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin/contractAmounts"
          element={
            <ProtectedAdminRoute>
              <AdminContractAmounts />
            </ProtectedAdminRoute>
          }
        />
      </Routes>

      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </BrowserRouter>
  );
};

export default App;
