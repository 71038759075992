// import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EngineeringLogin from '../components/Engineering/EngineeringLogin';
import Footer from '../components/Layout/Footer';
import Header from '../components/Layout/Header';
import { getAllSellers } from '../redux/actions/sellers';

const EngineeringLoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { seller, isSeller, isLoading } = useSelector((state) => state.seller);
  // const engBranchId = Cookies.get('branchId');

  useEffect(() => {
    if (isSeller === true) {
      navigate(`/dashboard`);
      dispatch(getAllSellers());
    }
  }, [isLoading, isSeller, seller]);

  return (
    <div>
      <Header activeHeading={1} />
      <EngineeringLogin />
      <Footer />
    </div>
  );
};

export default EngineeringLoginPage;
