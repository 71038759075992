import React from 'react'
import DashboardHeader from '../../components/Engineering/Layout/DashboardHeader'
import DashboardSideBar from '../../components/Engineering/Layout/DashboardSideBar'
import AllHoldings from "../../components/Engineering/AllHoldings";

const ShopAllEvents = () => {
  return (
    <div>
    <DashboardHeader />
    <div className="flex items-start justify-between w-full">
      <div className="w-[70px] lg:w-[320px] xl:w-[290px] md:w-[60px]">
        <DashboardSideBar active={7} />
      </div>
      <AllHoldings />
    </div>
  </div>
  )
}

export default ShopAllEvents