import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCustomersAdmin } from '../../redux/actions/admin';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactPaginate from 'react-paginate';
import { useReactToPrint } from 'react-to-print';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

// import Loader from '../Layout/Loader';
const itemsPerPage = 20;

const AllContractAmounts = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { customers } = useSelector((state) => state.admin);
  const [searchTerm, setSearchTerm] = useState('');
  // const [searchShow, setSearchShow] = useState(false);
  const [allCustomers, setAllCustomers] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [currentPage, setCurrentPage] = useState(0);

  const conponentPDF = useRef();

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    const isItemExists =
      customers &&
      customers.filter(
        (item) =>
          item.totalPrice > 0 &&
          item.levels[item.levels?.length - 1]?.status !== 'Canceled'
      );
    setAllCustomers(isItemExists);
  }, [dispatch, customers]);

  useEffect(() => {
    dispatch(getAllCustomersAdmin());
  }, [dispatch]);

  const offset = currentPage * itemsPerPage;
  const paginatedData = allCustomers?.slice(offset, offset + itemsPerPage);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filteredProducts =
      allCustomers &&
      allCustomers.filter((person) => {
        return (
          person.name.toLowerCase().includes(term.toLowerCase()) ||
          person.phone.toLowerCase().includes(term.toLowerCase())
        );
      });

    if (term === '') {
      // setSearchShow(false);
      dispatch(getAllCustomersAdmin());
    } else {
      // setSearchShow(true);
      setAllCustomers(filteredProducts);
    }
  };

  // const pageStyle = '@page { size: 1in 2in }';

  const generatePDF = useReactToPrint({
    content: () => conponentPDF.current,
    pageStyle: `
    @page {
      size: A4 portrate;
      margin: 1cm;
    }
    @media print {
      body {
        margin: 1;
        padding: 1;
      }
    }
  `,
    documentTitle: 'All Contract Amounts',
    // onAfterPrint: () => alert('Data saved in PDF'),
  });

  const handleClear = () => {
    const isItemExists =
      customers && customers.filter((item) => item.totalPrice > 0);
    setAllCustomers(isItemExists);
  };

  const handleSelect = () => {
    let filtered = customers.filter((customer) => {
      let customerDate = new Date(customer['createdAt']);
      return customerDate >= startDate && customerDate <= endDate;
    });
    setStartDate(startDate);
    setEndDate(endDate);
    setAllCustomers(filtered);
  };

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    // key: 'selection',
  };

  return (
    <div className="container mt-[65px]  px-3 mx-auto">
      <div className="container px-3 mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 pt-2 gap-3 mr-3">
        <div className="flex justify-start mt-3 items-center w-full">
          <div className="relative flex w-full justify-center items-center px-3 items-stretch">
            <p className="flex justify-center items-center px-2 text-[12px] md:text-[14px] lg:text-[15px] xl:text-[16px] rtl:xl:text-[20px] font-[500]">
              {' '}
              {t('admin.search')}{' '}
            </p>
            <input
              type="search"
              className="relative m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.30rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-[#F4AA8F] focus:text-neutral-700  focus:outline-none "
              placeholder="Search by name or phone"
              aria-label="Search"
              aria-describedby="button-addon1"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        <div className="w-full flex  justify-start items-center">
          <div className="container px-6 mx-3 mt-3 grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3  gap-3">
            <div className=" flex justify-start items-center ">
              <p className="flex justify-center items-center px-2">{t('admin.from')}</p>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                className="border py-1 px-2 w-full border-[#F4AA8F] rounded"
                closeOnScroll={true}
                // isClearable
              />
            </div>
            <div className=" flex justify-start items-center">
              <p className="flex justify-center items-center px-2">{t('admin.to')}</p>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                className="border py-1 px-2 w-full border-[#F4AA8F] rounded"
                closeOnScroll={true}
                // isClearable
              />
            </div>
            <div className="flex justify-start items-center">
              <div className="">
                <button
                  className="relative   z-[2] flex justify-center items-center rounded  bg-[#404041]  px-6 py-2 text-xs font-medium uppercase leading-tight text-[#F4AA8F] shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
                  type="button"
                  id="button-addon1"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  onClick={handleSelect}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      // fillrule="evenodd"
                      d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                      // cliprule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              <div className=" px-1">
                <button
                  className="relative  z-[2] flex justify-center items-center rounded bg-gray-500 px-6 py-[0.65rem] text-xs rtl:text-md font-medium uppercase leading-tight text-[#fff] shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
                  type="button"
                  id="button-addon1"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  onClick={handleClear}
                >
                  {t('admin.clear')}
                </button>
              </div>
              <div className="">
                <button
                  className="z-[2] flex justify-center items-center rounded text-[#F4AA8F] hover:text-[#fff]  appearance-none bg-[#404041] hover:bg-[#222222] text-center block px-6 py-[0.35rem] border border-gray-300 rounded"
                  onClick={generatePDF}
                >
               {t('admin.print')}{' '}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full border-b py-1"></div>

      <div ref={conponentPDF} className="container  mx-auto">
        <div className="mx-auto grid grid-cols-2 gap-4">
          <div className="flex justify-start items-center px-3 py-3 ">
            <p className=" text-[10px] md:text-[16px] lg:text-[16px] xl:text-[16px] rtl:xl:text-[20px] font-[500]">
            {t('admin.all_contract_amounts')}{' '}
            </p>
          </div>
          <div className="flex justify-end items-center px-3 py-3 ">
            <span className="text-black text-[10px] md:text-[14px] lg:text-[15px] xl:text-[16px]  font-[500]">
              ({allCustomers?.length}) {t('admin.customer')}
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <div className=" -my-2 overflow-x-auto ">
            <div className="inline-block min-w-full py-2 align-middle  md:px-2 lg:px-2">
              <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className="bg-[#F1F2F7] dark:bg-gray-800">
                    <tr>
                      <th
                        scope="col"
                        className="py-3 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                        <span>{t('admin.name')}</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                        <span>{t('admin.engineer_name')}</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                        <span>{t('admin.amount')}</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                        <span>{t('customer.status')}</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                        <span>{t('admin.create_at')}</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                    {paginatedData?.map((item, index) => (
                      <tr key={index}>
                        <td className="px-4 py-3 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item.name}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-3 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item.engineering}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-3 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item.totalPrice}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-3 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item.levels[item.levels.length - 1]?.status}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-3 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item.createdAt?.split(' ')[0]}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center mt-2">
        <ReactPaginate
          breakLabel={'...'}
          nextLabel={<AiOutlineRight size="20" className="rtl:-scale-x-100" />}
          previousLabel={
            <AiOutlineLeft size="20" className="rtl:-scale-x-100" />
          }
          pageRangeDisplayed={2}
          pageCount={Math.ceil(allCustomers?.length / itemsPerPage)}
          onPageChange={handlePageChange}
          marginPagesDisplayed={2}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="active"
          shape="rounded"
        />
      </div>

      <div className="flex justify-between items-center mt-3 px-2 mr-3">
        <div className="w-full min-h-[45vh] rounded"></div>
        <div className="w-full min-h-[45vh] rounded"></div>
        <div className="w-full min-h-[45vh] px-6 rounded">
          <h3 className="text-[22px] font-Poppins text-center pb-1">
          {t('admin.total_contract')}
          </h3>
          <p className="w-full text-[24px] font-[600] p-1 border border-[#F4AA8F] text-center ">
            ${' '}
            {allCustomers &&
              allCustomers.reduce((acc, item) => acc + item.totalPrice, 0)}
          </p>
        </div>
      </div>
    </div>
    //  )}
    //  </>
  );
};

export default AllContractAmounts;
