import React from 'react';
import AdminHeader from '../components/Layout/AdminHeader';
import AdminSideBar from '../components/Admin/Layout/AdminSideBar';
import AllContracts from '../components/Admin/AllContracts';

const AdminAllContracts = () => {
  return (
    <div>
      <AdminHeader />
      <div className="flex items-start justify-between w-full">
        <div className="w-[70px] lg:w-[320px] xl:w-[290px] md:w-[60px]">
          <AdminSideBar active={11} />
        </div>
        <AllContracts />
      </div>
    </div>
  );
};

export default AdminAllContracts;
