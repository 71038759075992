import React from 'react';
import styles from '../../styles/styles';

const Footer = () => {
  return (
    <div className="bg-[#222222] text-black border-2 border-gray-200 bottom-0">
      <div className={`${styles.section}`}>
        <div
          className="
         text-center py-8 text-gray-400 text-sm "
        >
          <span>© 2023 Delos International</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
