import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBranchs } from '../../../redux/actions/branch';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { branches } = useSelector((state) => state.branches);

  useEffect(() => {
    dispatch(getAllBranchs());
  }, [dispatch]);

  const headerImg = '../../../../images/bannerHome.jpg';
  return (
    <div className={`relative flex lg:flex-row h-full w-full  items-center`}>
      {/* <div className=" relative flex  w-full h-full items-center bg-gradient-to-r from-gray-500 to-transparent"> */}
      <div>
        <img
          className="rtl:-scale-x-100 object-cover w-auto h-[638px]"
          src={headerImg}
          alt="sofa"
        />
      </div>

      <div className="rtl:flex-row-reverse px-16    h-full w-auto sm:w-auto absolute">
        <div className="w-screen h-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pt-3 gap-10 ">
          <div className="flex-wrap mt-4 items-center justify-center">
            {branches &&
              branches.map((item, index) => (
                <div className="px-20  py-2 flex justify-start items-center rounded  w-full">
                  <Link to={`/engineer-login/${item._id}`} key={index}>
                    <div
                      onClick={() =>
                        Cookies.set('branchId', `${item._id}`, { expires: 7 })
                      }
                      className="px-2 cursor-pointer bg-[#fff] flex rounded justify-between items-center w-full  lg:w-60 md:w-50 lg:px-4 md:px-4 h-12 text-[#404041] hover:bg-[#413632] hover:text-[#F4AA8F]"
                    >
                      <p className="text-xl font-medium leading-5">
                        {item.name}
                      </p>
                      <div>
                        <HiArrowNarrowRight
                          size={25}
                          className="rtl:-scale-x-100"
                        />
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
          </div>
          {/* <div className="flex items-center justify-end w-2/5"></div> */}
          <div className="flex px-20 sm:px-30 md:px-44 lg:px-10 xl:px-8 rtl:px-20  items-center justify-end w-full">
            {/* <div className="px-20 py-4 flex justify-start w-3/5 leading-2"></div>
              <div className="px-32  py-4 flex justify-start w-1/5 leading-2"></div> */}
            <div className="px-4 rtl:px-4 py-4 flex-wrap items-center justify-end leading-8">
              <p className="text-[30px] rtl:px-1 font-semibold  leading-5">
                {t('main.main_customer')}
              </p>
              <h1 className="px-1 rtl:px-0 py-3 text-[36px] font-semibold  leading-5">
                {t('main.main_services')}
              </h1>
            </div>
            {/* <div className="px-10 py-2 flex justify-start w-full leading-2">
          </div> */}
          </div>
        </div>
      </div>
      {/* </div> */}

      {/* </div> */}
    </div>
  );
};

export default Hero;
