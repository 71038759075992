import { React, useEffect, useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import styles from '../../styles/styles';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { server } from '../../server';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { loadSeller } from '../../redux/actions/user';
import { useTranslation } from 'react-i18next';

const EngineeringLogin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const { id: branchId } = useParams();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(loadSeller());
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await axios
      .post(
        `${server}/engineering/login-engineering`,
        {
          branchId,
          name,
          password,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success('Login Success!');
        Cookies.set('seller_token', `${res.data.token}`, { expires: 7 });
        dispatch(loadSeller());
        navigate('/dashboard');
        window.location.reload(true);
        Cookies.set('branchId', `${branchId}`, { expires: 7 });
        // console.log(seller.role);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        Cookies.remove('branchId');
      });
  };

  return (
    <div className="h-[638px] bg-gray-50 flex flex-col justify-center py-12 px-4 lg:px-2 md:px-2 sm:px-4 ">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {t('main.Login_account')}
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                {t('admin.user_name')}
              </label>
              <div className="mt-1">
                <input
                  type="name"
                  name="name"
                  autoComplete="name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                {t('admin.password')}
              </label>
              <div className="mt-1 relative">
                <input
                  type={visible ? 'text' : 'password'}
                  name="password"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
                {visible ? (
                  <AiOutlineEye
                    className="absolute  ltr:right-2 rtl:left-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute  ltr:right-2 rtl:left-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
              </div>
            </div>
            {/* <div className={`${styles.noramlFlex} justify-between`}>
              <div className={`${styles.noramlFlex}`}>
                <input
                  type="checkbox"
                  name="remember-me"
                  id="remember-me"
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  onChange={() => setRemember(true)}
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Remember me
                </label>
              </div>
              <div className="text-sm">
                <a
                  href=".forgot-password"
                  className="font-medium text-blue-600 hover:text-blue-500"
                >
                  Forgot your password?
                </a>
              </div>
            </div> */}
            <div>
              <button
                type="submit"
                className="group relative w-full h-[40px]  justify-center py-2 px-4  bg-[#413632] flex rounded font-medium lg:px-4 md:px-4 text-white hover:bg-[#413632] hover:text-[#F4AA8F]"
              >
                <p className=" font-medium leading-5"> {t('main.login')} </p>
              </button>
            </div>
            {/* <div className="group relative w-full h-[40px] flex justify-center py-2">
              <button
                type="submit"
                className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                Submit
              </button>
            </div> */}
            <div className={`${styles.noramlFlex} w-full`}>
              <h4>{t('main.not_have_account')}</h4>
              <span className="text-blue-600 pl-2">
                {t('main.contact_admin')}
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EngineeringLogin;
