import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
};

export const branchReducer = createReducer(initialState, {
  branchCreateRequest: (state) => {
    state.isLoading = true;
  },
  branchCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.branch = action.payload;
    state.success = true;
  },
  branchCreateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  //get all Categories of shop
  getBranchIdRequest: (state) => {
    state.isLoading = true;
  },
  getBranchIdSuccess: (state, action) => {
    //state.isBranch = true;
    state.isLoading = false;
    state.branch = action.payload;
  },
  getBranchIdFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // get all Branchs
  getAllBranchsRequest: (state) => {
    state.isLoading = true;
  },
  getAllBranchsSuccess: (state, action) => {
    state.isLoading = false;
    state.branches = action.payload;
  },
  getAllBranchsFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // update Categories
  // updateCategoryRequest: (state) => {
  //   state.isLoading = true;
  // },
  // updateCategorySuccess: (state, action) => {
  //   state.isLoading = false;
  //   state.Category = action.payload;
  //   state.success = true;
  // },
  // updateCategoryFailed: (state, action) => {
  //   state.isLoading = false;
  //   state.error = action.payload;
  //   state.success = false;
  // },

  clearErrors: (state) => {
    state.error = null;
  },
});
